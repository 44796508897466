import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['count', 'item', 'container']
  static classes = ['any', 'empty']
  static values = {
    empty: { type: String, default: '0' },
  }

  itemTargetConnected() {
    this.setCounter()
  }

  itemTargetDisconnected() {
    this.setCounter()
  }

  setCounter() {
    if (this.hasContainerTarget) {
      if (this.itemCount === 0) {
        this.containerTarget.classList.add(...this.emptyClasses)
        this.containerTarget.classList.remove(...this.anyClasses)
      } else {
        this.containerTarget.classList.add(...this.anyClasses)
        this.containerTarget.classList.remove(...this.emptyClasses)
      }
    }

    if (this.hasCountTarget) {
      this.countTarget.textContent = this.counterDisplay
    }
  }

  get counterDisplay() {
    if (this.itemCount === 0) {
      return this.emptyValue
    }

    return this.itemCount
  }

  get itemCount() {
    return this.itemTargets.length
  }
}
