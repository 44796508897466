import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['parent', 'child']

  connect() {
    this.sync()
  }

  sync() {
    if (this.parentEnabled) {
      this.enable()
    } else {
      this.disable()
    }
  }

  enable() {
    this.childTargets.forEach((c) => (c.disabled = false))
  }

  disable() {
    this.childTargets.forEach((c) => (c.disabled = true))
  }

  get parentEnabled() {
    return this.parentTarget.selected || this.parentTarget.checked
  }
}
