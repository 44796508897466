import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'section']
  static classes = ['active']
  static values = {
    initialSelectedId: String,
  }

  connect() {
    this.setSelectedSection(this.initialSelectedIdValue)
  }

  select({ currentTarget }) {
    const selectedId = currentTarget.dataset.sectionId
    this.setSelectedSection(selectedId)
  }

  setSelectedSection(selectedId) {
    this.sectionTargets.forEach((section) => {
      const sectionId = section.dataset.sectionId
      section.classList.toggle('hidden', sectionId !== selectedId)
    })

    this.tabTargets.forEach((tab) => {
      const sectionId = tab.dataset.sectionId
      if (sectionId === selectedId) {
        tab.classList.add(...this.activeClasses)
      } else {
        tab.classList.remove(...this.activeClasses)
      }
    })
  }
}
