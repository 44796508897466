import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }
  static targets = ['anchorable', 'operator', 'expectedValue']

  onChange(_event) {
    get(this.urlValue, {
      query: {
        anchorable_id: this.anchorableTarget?.value,
        operator: this.operatorTarget?.value,
        expected_value: this.expectedValueTarget?.value,
      },
      responseKind: 'turbo-stream',
    })
  }
}
