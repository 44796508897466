import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

export default class extends Controller {
  connect() {
    this.control = new TomSelect(this.element, {
      create: true,
      createOnBlur: true,
      plugins: ['remove_button'],
      onItemAdd: function () {
        // After a tag is added, clear any partially entered text
        // that was used for filtering and reload the options
        this.setTextboxValue('')
        this.refreshOptions()
      },
      onInitialize: function () {
        this.wrapper.classList.remove('hidden')
      },
    })
  }

  disconnect() {
    if (this.control) {
      this.control.destroy()
    }
  }
}
