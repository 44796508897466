import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dialog', 'title', 'iframe']

  open(event) {
    if (this.hasTitleTarget && event.params.title) {
      this.titleTarget.innerHTML = event.params.title
    }
    if (this.hasIframeTarget && event.params.url) {
      this.iframeTarget.src = event.params.url
    }
    this.dialogTarget.showModal()
  }

  close() {
    if (this.hasTitleTarget) {
      this.titleTarget.innerHTML = 'Loading...'
    }
    if (this.hasIframeTarget) {
      this.iframeTarget.src = ''
    }
    this.dialogTarget.close()
  }

  clickOutside({ target }) {
    if (target == this.dialogTarget) {
      this.close()
    }
  }
}
