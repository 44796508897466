import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    this.timeouts = this.containerTargets.map((notice) => {
      return setTimeout(() => {
        notice.classList.add(
          'transition-all',
          'duration-500',
          'ease-in-out',
          'scale-y-75',
          'opacity-0',
          'pointer-events-none'
        )
        notice.remove()
      }, 5000)
    })
  }

  disconnect() {
    this.timeouts.forEach((timeout) => clearInterval(timeout))
  }

  close(event) {
    let container = event.currentTarget
    container.classList.add(
      'transition-all',
      'duration-500',
      'ease-in-out',
      'scale-y-75',
      'opacity-0',
      'pointer-events-none'
    )
    container.remove()
  }
}
