import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    location: String,
  }

  connect() {
    this.scrollToLocation()
    this.element.remove()
  }

  scrollToLocation() {
    this.targetElement.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    })
  }

  get targetElement() {
    return document.getElementById(this.locationValue)
  }
}
