import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['divider']

  dividerTargetConnected() {
    this.syncDividers()
  }

  syncDividers() {
    const firstVisibleDivider = this.dividerTargets.find(
      (target) => !target.parentElement.classList.contains('hidden')
    )

    if (firstVisibleDivider) {
      firstVisibleDivider.classList.add('hidden')
    }
  }

  onGroupRemoved() {
    this.element.querySelectorAll('input,select').forEach((input) => {
      input.removeAttribute('required')
    })

    this.element.classList.add('hidden')
  }
}
