import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.onToggle = this.#onToggle.bind(this)
    this.popoverVisible = false

    this.popoverTargetElement = this.element.popoverTargetElement
    if (!this.popoverTargetElement) {
      this.popoverTargetElement = document.querySelector(
        `#${this.element.dataset.popoverTarget}`
      )
    }

    this.popoverTargetElement.addEventListener('toggle', this.onToggle)
  }

  disconnect() {
    this.popoverTargetElement.removeEventListener('toggle', this.onToggle)
  }

  onClick(_event) {
    if (this.popoverVisible) {
      this.popoverTargetElement.hidePopover()
    } else {
      this.popoverTargetElement.showPopover()
    }
  }

  #onToggle(event) {
    this.popoverVisible = event.newState === 'open'
    this.element.dataset.overlayOpen = event.newState === 'open'
  }
}
