import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dependentInput']

  setValue(evt) {
    this.dependentInputTarget.value =
      evt.target.dataset.dependentInputValueToSet
  }
}
