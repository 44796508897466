import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['panel']

  clickOutside({ target }) {
    if (
      this.panelTarget.contains(target) ||
      this.panelTarget.contains(document.activeElement)
    ) {
      return
    }

    this.close()
  }

  close() {
    this.element.remove()
  }
}
