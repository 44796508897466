import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['radioButton']
  static classes = ['checked', 'unchecked']

  connect() {
    this.reloadClasses()
  }

  select({ params }) {
    const selectedRadioButton = document.getElementById(params['id'])
    selectedRadioButton.checked = true
    this.reloadClasses()
  }

  // private

  indicatorFor(radioButton) {
    const id = radioButton.getAttribute('aria-controls')
    return id && document.getElementById(id)
  }

  reloadClasses() {
    for (const target of this.radioButtonTargets) {
      if (target.checked) {
        this.indicatorFor(target).classList.remove(...this.uncheckedClasses)
        this.indicatorFor(target).classList.add(...this.checkedClasses)
      } else {
        this.indicatorFor(target).classList.remove(...this.checkedClasses)
        this.indicatorFor(target).classList.add(...this.uncheckedClasses)
      }
    }
  }
}
