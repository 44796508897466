import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ['button', 'nav', 'main', 'wrapper']

  connect() {
    if (this.savedCollapsedState) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  expand() {
    this.clearCollapsedState()
    this.navTarget.classList.remove('hidden')
    this.buttonTarget.classList.add('hidden')
    this.mainTarget.classList.add('lg:pl-[--side-nav-width]')
    this.wrapperTarget.classList.remove('sm:w-auto')
  }

  smoothExpand() {
    this.mainTarget.classList.add(
      'transition-[padding]',
      'ease-in-out',
      'duration-300'
    )

    this.expand()
    enter(this.navTarget)
  }

  smoothCollapse() {
    this.mainTarget.classList.add(
      'transition-[padding]',
      'ease-in-out',
      'duration-300'
    )
    this.mainTarget.classList.remove('lg:pl-[--side-nav-width]')

    leave(this.navTarget).then(() => {
      this.collapse()
    })
  }

  collapse() {
    this.wrapperTarget.classList.add('sm:w-auto')
    this.mainTarget.classList.remove('lg:pl-[--side-nav-width]')
    this.navTarget.classList.add('hidden')
    this.buttonTarget.classList.remove('hidden')
    this.setCollapsedState()
  }

  get savedCollapsedState() {
    return localStorage.getItem(this.collapsedKey) === 'true'
  }

  setCollapsedState() {
    localStorage.setItem(this.collapsedKey, 'true')
  }

  clearCollapsedState() {
    localStorage.removeItem(this.collapsedKey)
  }

  get collapsedKey() {
    return `nav-collapsed-${this.element.dataset['key']}`
  }
}
