import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['form', 'switch', 'fieldset']
  static values = { editUrl: String }

  toggle() {
    this.fieldsetTarget.classList.toggle('hidden')
    this.fieldsetTarget.disabled = !this.switchTarget.checked

    if (!this.switchTarget.checked) {
      this.formTarget.requestSubmit()
    }
  }

  relativeAnchorChanged(_event) {
    const formData = new FormData(this.formTarget)
    formData.delete('_method')
    formData.delete('authenticity_token')

    get(this.editUrlValue, {
      query: formData,
      responseKind: 'turbo-stream',
    })
  }
}
