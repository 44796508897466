import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['trigger']
  static values = {
    frameId: String,
  }

  triggerTargetConnected() {
    this.frameElement.reload()
  }

  // Private

  get frameElement() {
    return document.getElementById(this.frameIdValue)
  }
}
