import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['button', 'formField', 'filters']

  connect() {
    this.updateVisuals()
  }

  selectedToggle(event) {
    // Clearing the Turbo cache prevents Turbo from thinking it knows what
    // values to display on the form.
    Turbo.clearCache()

    this.toggleVisible()
    this.updateVisuals()
  }

  toggleVisible() {
    this.formFieldTarget.value = !this.isVisible()
  }

  isVisible() {
    return this.formFieldTarget.value === 'true'
  }

  updateVisuals() {
    if (this.isVisible()) {
      this.setButtonSelected(this.buttonTarget)
      this.filtersTarget.classList.remove('hidden')
    } else {
      this.setButtonDeselected(this.buttonTarget)
      this.filtersTarget.classList.add('hidden')
    }
  }

  setButtonDeselected(button) {
    button.classList.remove(...this.selectedClasses())
    button.classList.add(...this.deselectedClasses())
  }

  setButtonSelected(button) {
    button.classList.remove(...this.deselectedClasses())
    button.classList.add(...this.selectedClasses())
  }

  deselectedClasses() {
    return this.data.get('deselected-classes').split(' ')
  }

  selectedClasses() {
    return this.data.get('selected-classes').split(' ')
  }
}
