import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'directorySync',
    'sso',
    'auditLogs',
    'autoCreateUsers',
    'autoCreateUsersFieldset',
    'workOsOrganizationId',
    'adminDescription',
    'adminName',
    'adminEmail',
  ]

  connect() {
    this.sync()
  }

  sync() {
    if (this.directorySyncEnabled) {
      this.workOsOrganizationIdTarget.disabled = false

      if (this.autoCreateUsersTarget.checked) {
        this.autoCreateUsersTarget.click()
      }

      this.autoCreateUsersFieldsetTarget.disabled = true
      this.adminDescriptionTarget.disabled = true
      this.adminNameTarget.disabled = true
      this.adminEmailTarget.disabled = true
    } else {
      this.workOsOrganizationIdTarget.disabled = true

      this.autoCreateUsersFieldsetTarget.disabled = false
      this.adminDescriptionTarget.disabled = false
      this.adminNameTarget.disabled = false
      this.adminEmailTarget.disabled = false
    }

    if (this.ssoEnabled) {
      this.workOsOrganizationIdTarget.disabled = false

      if (!this.directorySyncEnabled) {
        this.adminDescriptionTarget.disabled = false
        this.adminNameTarget.disabled = false
        this.adminEmailTarget.disabled = false
      }
    }

    if (this.auditLogsEnabled) {
      this.workOsOrganizationIdTarget.disabled = false
    }
  }

  get directorySyncEnabled() {
    return this.directorySyncTarget.selected || this.directorySyncTarget.checked
  }

  get ssoEnabled() {
    return this.ssoTarget.selected || this.ssoTarget.checked
  }

  get auditLogsEnabled() {
    return this.auditLogsTarget.selected || this.auditLogsTarget.checked
  }
}
