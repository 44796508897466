import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['form']
  static values = { formUrl: String }

  select(event) {
    this.updateForm()
  }

  updateForm() {
    const formData = new FormData(this.formTarget)
    formData.delete('_method')
    formData.delete('authenticity_token')

    get(this.formUrlValue, {
      query: formData,
      responseKind: 'turbo-stream',
    })
  }
}
