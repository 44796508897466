import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'trigger']
  static classes = ['clamp']

  connect() {
    if (!this.isClamped()) {
      this.reveal()
    }
  }

  reveal() {
    this.contentTarget.classList.remove(this.clampClass)
    this.triggerTarget.classList.add('hidden')
  }

  isClamped() {
    return this.contentTarget.scrollHeight > this.contentTarget.clientHeight
  }
}
