import { Controller } from '@hotwired/stimulus'
import 'pdfjs-dist/build/pdf.mjs'

export default class extends Controller {
  static values = { workerSrc: String, url: String }
  static targets = ['canvas']

  connect() {
    pdfjsLib.GlobalWorkerOptions.workerSrc = this.workerSrcValue
    this.loadPdfPreview()
  }

  async loadPdfPreview() {
    const loadingTask = pdfjsLib.getDocument(this.urlValue)
    const pdf = await loadingTask.promise

    // Load information from the first page.
    const page = await pdf.getPage(1)

    // Scale the page viewport to fit the canvas
    let viewport = page.getViewport({ scale: 1 })
    const scale = this.canvasTarget.clientWidth / viewport.width
    viewport = page.getViewport({ scale })

    // Apply page dimensions to the `<canvas>` element.
    const context = this.canvasTarget.getContext('2d')
    this.canvasTarget.height = viewport.height
    this.canvasTarget.width = viewport.width

    // Render the page into the `<canvas>` element.
    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    }
    await page.render(renderContext)
  }
}
