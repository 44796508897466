import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'buttonText', 'link', 'loaderText']
  static classes = ['disabled']

  enableButton() {
    this.buttonTarget.disabled = false
    this.loaderTextTarget.classList.add('hidden')
    this.buttonTextTarget.classList.remove('hidden')
    // Re-enable the link
    this.linkTarget.classList.remove(...this.disabledClasses)
    this.linkTarget.parentElement.removeAttribute('data-tippy-content')
    this.linkTarget.parentElement.dataset['controller'] =
      this.linkTarget.parentElement.dataset['controller'].replace('tippy', '')
  }

  disableButton() {
    this.buttonTarget.disabled = true
    this.buttonTextTarget.classList.add('hidden')
    this.loaderTextTarget.classList.remove('hidden')
    // Disable a link - we can't use a button here due to file stream +
    // the button being inside a drawer.
    this.linkTarget.classList.add(...this.disabledClasses)
    this.linkTarget.parentElement.dataset['tippyContent'] =
      'The download process has been initiated'
    this.linkTarget.parentElement.dataset['controller'] =
      this.linkTarget.parentElement.dataset['controller'].concat(' tippy')

    setTimeout(() => {
      this.enableButton()
    }, 10000)
  }
}
