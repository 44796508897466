import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item']

  connect() {
    this.start()
  }

  start() {
    this.itemTargets.forEach((target) => {
      target.classList.add('animate-pulse')
    })
  }

  stop() {
    this.itemTargets.forEach((target) => {
      target.classList.remove('animate-pulse')
    })
  }

  stopIn(event) {
    setTimeout(() => {
      this.stop()
    }, event.params.delay)
  }
}
