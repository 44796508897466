import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'task', 'indicator']
  static values = { initialState: Boolean }

  connect() {
    this.collapsed = this.initialStateValue
    this.syncState()
  }

  taskTargetConnected() {
    this.syncState()
  }

  toggle() {
    this.collapsed = !this.collapsed
    this.syncState()
  }

  force({ currentTarget }) {
    this.collapsed = currentTarget.checked
    this.syncState()
  }

  syncState() {
    this.contentTarget.classList.toggle('hidden', this.collapsed)
    this.indicatorTarget.classList.toggle('rotate-180', !this.collapsed)
  }
}
