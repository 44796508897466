import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { overlayId: String }

  connect() {
    this.onToggle = this.onToggle.bind(this)

    this.overlayElement = document.getElementById(this.overlayIdValue)
    this.overlayElement.addEventListener('toggle', this.onToggle)
  }

  disconnect() {
    this.overlayElement.removeEventListener('toggle', this.onToggle)
  }

  onToggle(event) {
    this.element.dataset.overlayOpen = event.newState === 'open'
  }
}
