import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'menu', 'background']

  connect() {
    this.closeMenu()
  }

  toggleMenu(event) {
    event?.preventDefault()

    let state = this.data.get('state')

    if (state == 'closed') {
      this.openMenu()
    } else {
      this.closeMenu()
    }
  }

  closeMenu() {
    let openClasses = this.data.get('open-classes').split(' '),
      closedClasses = this.data.get('closed-classes').split(' '),
      backgroundClasses = this.data.get('background-classes').split(' ')

    this.menuTarget.classList.remove(...openClasses)
    this.menuTarget.classList.add(...closedClasses)
    this.buttonTarget.classList.remove('z-20')

    if (this.hasBackgroundTarget) {
      this.backgroundTarget.classList.remove(...backgroundClasses)
    }

    this.data.set('state', 'closed')
  }

  openMenu() {
    let openClasses = this.data.get('open-classes').split(' '),
      closedClasses = this.data.get('closed-classes').split(' '),
      backgroundClasses = this.data.get('background-classes').split(' ')

    this.menuTarget.classList.remove(...closedClasses)
    this.menuTarget.classList.add(...openClasses)
    this.buttonTarget.classList.add('z-20')

    if (this.hasBackgroundTarget) {
      this.backgroundTarget.classList.add(...backgroundClasses)
    }

    this.data.set('state', 'open')
  }

  closeOnClickOutside({ target }) {
    if (this.element.contains(target)) return
    if (this.element.contains(document.activeElement)) return

    this.closeMenu()
  }
}
