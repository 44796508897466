import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  closeOnClickOutside({ target }) {
    if (this.element.contains(target)) return
    if (this.element.contains(document.activeElement)) return

    this.close()
  }

  close() {
    this.element.open = false
  }
}
