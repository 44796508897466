import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    selector: String,
    nextElement: {
      type: Boolean,
      default: false,
    },
  }

  connect() {
    this.grabFocus()
    this.element.remove()
  }

  grabFocus() {
    if (this.nextElementValue) {
      this.element.nextElementSibling.focus()
      return
    }

    if (this.hasSelectorValue) {
      document.querySelector(this.selectorValue)?.focus()
    }
  }
}
