import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'input']

  connect() {
    this.sync()
  }

  sync() {
    if (this.sourceObjectSelected) {
      this.enable()
    } else {
      this.disable()
    }
  }

  enable() {
    this.inputTargets.forEach((c) => (c.disabled = false))
  }

  disable() {
    this.inputTargets.forEach((c) => (c.disabled = true))
  }

  get sourceObjectSelected() {
    return this.hasSourceTarget && this.sourceTarget.value
  }
}
