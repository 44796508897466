import { Controller } from '@hotwired/stimulus'
import { iframely } from '@iframely/embed.js'

export default class extends Controller {
  static targets = ['link']
  static values = { key: String }

  connect() {
    // If we use data-turbo-permanent, the controller may reconnect
    // but we've already replaced the link with the iframe.
    if (this.hasLinkTarget) {
      iframely.extendOptions({ key: this.keyValue, align: 'left' })
      iframely.load(this.linkTarget)
    }
  }
}
