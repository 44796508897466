import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    hasRequiredFields: Boolean,
    hasVisibleFields: Boolean,
    submitted: Boolean,
    disabledMarkDoneTooltip: String,
  }

  static outlets = ['tasks--action-bar']

  static targets = ['submitButton']

  static CONDITION_OPERATORS = {
    is_equal_to: (expected, value) => {
      return expected === value
    },
    is_not_equal_to: (expected, value) => {
      return expected !== value
    },
    contains: (expected, value) => {
      return value !== null && value !== undefined && value.includes(expected)
    },
    does_not_contain: (expected, value) => {
      return !value || value.trim().length === 0 || !value.includes(expected)
    },
    greater_than: (expected, value) => {
      return value !== null && value !== undefined && value > expected
    },
    less_than: (expected, value) => {
      return value !== null && value !== undefined && value < expected
    },
    before: (expected, value) => {
      return value !== null && value !== undefined && value < expected
    },
    after: (expected, value) => {
      return value !== null && value !== undefined && value > expected
    },
    starts_with: (expected, value) => {
      return value !== null && value !== undefined && value.startsWith(expected)
    },
    ends_with: (expected, value) => {
      return value !== null && value !== undefined && value.endsWith(expected)
    },
    is_empty: (_, value) => {
      return (
        !value ||
        value === undefined ||
        Number.isNaN(value) ||
        String(value).trim().length === 0
      )
    },
    is_filled: (_, value) => {
      return (
        value &&
        value !== undefined &&
        !Number.isNaN(value) &&
        String(value).trim().length > 0
      )
    },
  }

  static CONDITION_CASTERS = {
    number: (value) => {
      return parseFloat(value)
    },
    bool: (value) => {
      return Boolean(value)
    },
    date: (value) => {
      return new Date(value).getTime()
    },
    dateTime: (value) => {
      return new Date(value).getTime()
    },
    string: (value) => {
      return String(value)
    },
  }

  connect() {
    if (!this.hasVisibleFieldsValue) {
      this.submitButtonTarget.disabled = true
      this.tasksActionBarOutlet.syncMarkDoneButton({
        disable: true,
        tooltip: { show: false },
      })
    } else if (this.hasRequiredFieldsValue) {
      if (this.submittedValue) {
        this.tasksActionBarOutlet.syncMarkDoneButton({
          disable: false,
          tooltip: { show: false },
        })
      } else {
        this.tasksActionBarOutlet.syncMarkDoneButton({
          disable: true,
          tooltip: {
            show: true,
            content: this.disabledMarkDoneTooltipValue,
          },
        })
      }
    }
  }

  toggleVisibilityAnchoredFields(event) {
    const operators = this.constructor.CONDITION_OPERATORS
    const fieldsetQuery = 'fieldset[id^="fieldset_tasks_form_submission_"]'
    const conditionsQuery = 'div[id^="visibility_metadata_"]'
    const formSubmission = event.target.closest(fieldsetQuery)
    const conditionsMetadata = formSubmission.querySelector(conditionsQuery)

    if (conditionsMetadata) {
      for (let condition of conditionsMetadata.children) {
        const [expected, value] = this.castValues(
          condition.dataset.expectedValue,
          event.target.value,
          condition.dataset.fieldType
        )

        const operator = operators[condition.dataset.operator]
        const matches = operator(expected, value)
        const fieldset = this.fieldsetFor(condition.dataset.anchoredId)
        const actionShow = Boolean(condition.dataset.conditionAction == 'show')
        if (matches) {
          fieldset.disabled = !actionShow
        } else {
          fieldset.disabled = actionShow
        }
      }
    }
  }

  castValues(expected, value, fieldType) {
    const casters = this.constructor.CONDITION_CASTERS
    const caster = casters[fieldType] || casters.string
    return [caster(expected), caster(value)]
  }

  fieldsetFor(id) {
    return this.element.querySelector(`#fieldset_tasks_form_submission_${id}`)
  }
}
