import { Controller } from '@hotwired/stimulus'
import { observeMutations } from '../helpers/mutation_observer_helpers'

export default class extends Controller {
  static targets = ['container']
  static values = { attributeName: String }

  connect() {
    observeMutations.bind(this)(this.setElementContent, this.element, { attributes: true })
  }

  setElementContent(events) {
    events.forEach((event) => {
      if (event.type === "attributes" && event.attributeName == `data-${this.attributeNameValue}`) {
        this.containerTarget.textContent = this.element.dataset[this.camelize(this.attributeNameValue)]
      }
    })
  }

  camelize(str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  }
}
