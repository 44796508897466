import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
  }

  connect() {
    this.interval = setInterval(async () => {
      await get(this.urlValue, { responseKind: 'turbo-stream' })
    }, 1000)
  }

  disconnect() {
    clearInterval(this.interval)
  }
}
