import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'output']

  connect() {
    this.calculate()
  }

  calculate() {
    this.outputTarget.value = this.inputTargets
      .filter((c) => c.checked)
      .map((c) => c.value)
      .join(';')

    this.outputTarget.dispatchEvent(new Event('change'))
  }
}
