import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['lazyContent', 'lazyTemplate']
  open() {
    this.element.showModal()

    if (this.hasLazyContentTarget) {
      this.lazyContentTarget.innerHTML = this.lazyTemplateTarget.innerHTML
    }
  }

  close() {
    this.element.close()

    if (this.hasLazyContentTarget) {
      this.lazyTemplateTarget.innerHTML = this.lazyContentTarget.innerHTML
      this.lazyContentTarget.innerHTML = ''
    }
  }

  clickOutside({ target }) {
    if (target == this.element) {
      this.close()
    }
  }
}
