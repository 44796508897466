import { Controller } from '@hotwired/stimulus'
import { nextFrame } from '../../helpers/timing_helpers'

const opacityTransition = 'opacity 300ms ease-in-out 100ms'

export default class extends Controller {
  connect() {
    this.fadeIn()
  }

  // private

  fadeIn = () => {
    this.perform()
  }

  async perform() {
    this.content.style.transition = opacityTransition

    await nextFrame()

    this.content.classList.remove('opacity-0')
    this.content.classList.add('opacity-100')

    if (this.shouldScrollToPhase) {
      this.scrollToPhase()
    } else if (this.shouldScrolltoGate) {
      this.scrollToGate()
    } else {
      this.scrollToTask()
    }
  }

  scrollToPhase() {
    const params = new URLSearchParams(window.location.search)
    const phaseId = params.get('phase_id')

    if (phaseId) {
      const phase = document.querySelector(`#phase_${phaseId}`)
      if (phase) {
        phase.scrollIntoView()
        if (phase.ariaExpanded == 'false') {
          phase.click()
        }
      }
    }
  }

  scrollToTask() {
    const params = new URLSearchParams(window.location.search)
    const taskId = params.get('from_task_id')

    if (taskId) {
      const task = document.querySelector(`#list_task_${taskId}`)

      if (task) {
        task.scrollIntoView()
        scrollBy(0, -5)
      }
    }
  }

  scrollToGate() {
    const params = new URLSearchParams(window.location.search)
    const gateId = params.get('gate_id')

    if (gateId) {
      const gate = document.querySelector(`#plans_gate_${gateId}`)

      if (gate) {
        gate.scrollIntoView()
        scrollBy(0, -5)
      }
    }
  }

  get shouldScrollToPhase() {
    const params = new URLSearchParams(window.location.search)
    return params.has('phase_id')
  }

  get shouldScrolltoGate() {
    const params = new URLSearchParams(window.location.search)
    return params.has('gate_id')
  }

  // NOTE: The content element should have opacity set to 0 beforehand to avoid flashing.
  get content() {
    const contentElementId = this.element.getAttribute('aria-controls')
    return document.getElementById(contentElementId)
  }
}
