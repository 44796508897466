import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['label']

  connect() {
    this.boundReplace = this.replace.bind(this)

    for (const event of this.events) {
      this.element.addEventListener(event, this.boundReplace)
    }
  }

  disconnect() {
    for (const event of this.events) {
      this.element.removeEventListener(event, this.boundReplace)
    }
  }

  // private

  get events() {
    return this.labelTargets
      .map((label) => label.dataset['event'])
      .filter((el, i, ary) => ary.indexOf(el) === i)
  }

  labelTargetsFor(event) {
    return this.labelTargets.filter((el) => el.dataset['event'] === event)
  }

  replace(e) {
    for (const element of this.labelTargetsFor(e.type)) {
      element.innerHTML = e.detail[this.labelKindFor(element)]
    }
  }

  labelKindFor(element) {
    return element.dataset['labelKind'] == 'plural'
      ? 'pluralizedLabel'
      : 'label'
  }
}
