import { Controller } from '@hotwired/stimulus'
import Tribute from 'tributejs'
import Trix from 'trix'

export default class extends Controller {
  static values = { url: String }

  connect() {
    this.editor = this.element.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.element)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: (item, _) => {
        return item.name + item.email
      },
      values: this.fetchMentionableOptions.bind(this),
      menuItemTemplate: (item) => {
        if (item.original.name) {
          return `
            <div class="flex items-center space-x-2 cursor-pointer">
              <span class="text-sm font-medium text-neutral-900 truncate">${item.original.name}</span>
              <span class="text-sm text-neutral-400 truncate">${item.original.email}</span>
            </div>
          `
        } else {
          return `
            <span class="text-sm font-medium text-neutral-900 truncate">${item.original.email}</span>
          `
        }
      },
      noMatchTemplate: () => {
        return `
          <div class="px-2">
            <span class="text-sm text-neutral-400">No results found</span>
          </div>
        `
      },
    })
    this.tribute.attach(this.element)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.element.addEventListener('tribute-replaced', this.replaced)
  }

  fetchMentionableOptions(text, callback) {
    const url = new URL(this.urlValue)
    url.search = new URLSearchParams({ q: text })

    fetch(url, { headers: { Accept: 'application/json' } })
      .then((response) => response.json())
      .then((opts) => callback(opts))
      .catch((_) => callback([]))
  }

  replaced(e) {
    if (e.detail.item) {
      let mention = e.detail.item.original
      let attachment = new Trix.Attachment({
        sgid: mention.sgid,
        content: mention.content,
      })
      this.editor.insertAttachment(attachment)
      this.editor.insertString(' ')
    }
  }

  _pasteHtml(_, startPos, endPos) {
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([position - endPos + startPos, position + 1])
    this.editor.deleteInDirection('backward')
  }
}
