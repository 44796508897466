import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['button', 'icon', 'spinner']

  updateStatus(event) {
    this.preventClick(event)
    this.toggleButton()

    const taskId = event.currentTarget.dataset.taskId
    const isCompleted = event.currentTarget.dataset.taskIsCompleted
    const markAsDone = isCompleted === 'false'

    const data = new FormData()
    data.append('done', markAsDone)
    const taskUrl = '/tasks/' + taskId + '/inline/completions'

    patch(taskUrl, {
      body: data,
      responseKind: 'turbo-stream',
    })
  }

  toggleButton() {
    this.iconTarget.classList.toggle('hidden')
    this.spinnerTarget.classList.toggle('hidden')
  }

  preventClick(event) {
    event.preventDefault()
  }
}
