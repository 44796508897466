import Pickr from '@simonwep/pickr'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'swatch']

  connect() {
    this.swatchTarget.style.backgroundColor = this.inputTarget.value

    this.component = Pickr.create({
      el: this.inputTarget,
      default: this.inputTarget.value,
      theme: 'nano',
      position: 'top-middle',
      closeOnScroll: true,
      useAsButton: true,
      components: {
        preview: true,
        opacity: false,
        hue: true,
        interaction: {
          input: true,
          save: true,
        },
      },
    })

    this.component.on('save', (color) => {
      color.a = 1 // Don't support transparency, force alpha to 1
      this.inputTarget.value = color.toHEXA().toString()
      this.inputTarget.dispatchEvent(new Event('change'))
      this.component.hide()
      this.swatchTarget.style.backgroundColor = this.inputTarget.value
    })
  }

  disconnect() {
    if (this.component) {
      this.component.destroyAndRemove()
    }
  }
}
