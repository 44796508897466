import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['form', 'input', 'saved']

  submit(event) {
    event.preventDefault
    let form
    if (this.hasFormTarget) {
      form = this.formTarget
    } else {
      form = this.inputTarget.closest('form')
    }
    this.data.set('last-edit', Date.now())
    Rails.fire(form, 'submit')
    Turbo.clearCache()
  }

  onSuccess() {
    if (this.hasSavedTarget) {
      clearTimeout(savedTimer)
      this.inputTarget.classList.remove('bg-red-100')
      this.savedTarget.classList.remove('opacity-0', 'invisible')
      let savedTimer = setTimeout(() => {
        this.savedTarget.classList.add('opacity-0', 'invisible')
      }, 2500)
    }
  }

  onError() {
    console.log('Error updating item ' + Date.now())
    this.inputTarget.classList.add('bg-red-100')
  }
}
