import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['parent', 'child']

  connect() {
    this.syncParent()
  }

  toggle(e) {
    e.preventDefault()

    this.childTargets.forEach((checkbox) => {
      if (!checkbox.disabled) {
        checkbox.checked = e.target.checked
      }
    })
  }

  syncParent() {
    const total = this.childTargets.length
    const checkedCount = this.childTargets.filter((el) => el.checked).length

    this.parentTarget.checked = checkedCount === total
    this.parentTarget.indeterminate = checkedCount > 0 && checkedCount < total
  }
}
