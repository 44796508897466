import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'result']
  static values = { hideOn: String }

  connect() {
    this.sync()
  }

  sync() {
    const selectedValue = this.sourceTarget.value

    this.resultTarget.classList.toggle(
      'hidden',
      this.valuesToHide.includes(selectedValue)
    )
  }

  get valuesToHide() {
    return this.hideOnValue.split(',')
  }
}
