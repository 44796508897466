import { Controller } from '@hotwired/stimulus'
import { observeMutations } from '../helpers/mutation_observer_helpers'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['container']
  static values = {
    selector: String,
    frameId: String,
    url: String,
    enabled: Boolean,
    border: Boolean,
  }

  connect() {
    observeMutations.bind(this)(this.reloadPageIfEmpty, this.containerTarget)
  }

  reloadPageIfEmpty() {
    if (this.enabledValue) {
      const elements = this.containerTarget.querySelectorAll(this.selectorValue)
      if (elements.length === 0) {
        this.containerTarget.innerHTML = `
          <div class="h-36 flex items-center justify-center mt-2 rounded-lg w-full ${
            this.borderValue ? 'border border-neutral-50' : ''
          }">
            <div role="loader" class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]">
              <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading plans to catch up...
              </span>
            </div>
          </div>
        `

        Turbo.visit(this.urlValue, { frame: this.frameIdValue })
      }
    }
  }
}
