import { Controller } from '@hotwired/stimulus'
import { copyText } from '../../helpers/clipboard_helpers'

import tippy from 'tippy.js'

export default class extends Controller {
  static targets = ['copyButton']
  static values = { tooltip: String }

  copy() {
    copyText(this.copyButtonTarget.innerText)
    let tippyInstance = tippy(this.copyButtonTarget, {
      content: this.tooltipValue,
      trigger: 'manual',
    })
    tippyInstance.show()

    setTimeout(() => {
      tippyInstance.hide()
      tippyInstance.destroy()
    }, 2000)
  }
}
