import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
  }

  connect() {
    this.interval = setInterval(async () => {
      const response = await get(this.urlValue, { responseKind: 'json' })
      if (response.ok) {
        const body = await response.json
        if (body.status === 'completed') {
          clearInterval(this.interval)
          Turbo.visit(body.redirect_path, { action: 'replace' })
        } else if (body.status === 'failed') {
          clearInterval(this.interval)
          Turbo.visit('/500', { action: 'replace' })
        }
      }
    }, 500)
  }

  disconnect() {
    clearInterval(this.interval)
  }
}
