import { Controller } from '@hotwired/stimulus'

class NullEvent {
  preventDefault() {}
}

export default class extends Controller {
  static targets = ['link', 'container', 'showButton']
  static classes = ['selected', 'deselected']

  select({ target }) {
    this.deselectAllTabs()
    this.selectTab(target)
  }

  trapEnter(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  open(event) {
    event.preventDefault()
    const element = this.linkTargets.find(
      (element) => element.dataset['preselect'] != 'false'
    )
    element.click()
    this.containerTarget.classList.remove('hidden')
  }

  close(event) {
    event.preventDefault()
    this.containerTarget.classList.add('hidden')
  }

  closeOnClickOutside({ target }) {
    if (
      this.showButtonTarget.contains(target) ||
      this.containerTarget.contains(target) ||
      this.containerTarget.contains(document.activeElement)
    ) {
      return
    }

    this.close(new NullEvent())
  }

  // Private methods:

  deselectAllTabs() {
    this.linkTargets.forEach((element) => {
      element.classList.remove(...this.selectedClasses)
      element.classList.add(...this.deselectedClasses)
    })
  }

  linkTargetConnected(element) {
    if (element.dataset['preselect'] != 'false') element.click()
  }

  selectTab(element) {
    element.classList.remove(...this.deselectedClasses)
    element.classList.add(...this.selectedClasses)
  }
}
