import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['item']
  static values = {
    url: String,
    groupId: String,
  }

  connect() {
    Sortable.create(this.element, {
      handle: '.sortable-handle',
      group: `roles-${this.groupIdValue}`,
      animation: 150,
      filter: '.sortable-ignore',
      onUpdate: (e) => this.reorderItems(e),
      onAdd: (e) => this.reorderItems(e),
    })
  }

  reorderItems(e) {
    if (e.pullMode === 'clone') {
      return
    }

    const data = new FormData()
    data.append('ids', this.orderedRoleIds)

    patch(this.urlValue, {
      body: data,
      responseKind: 'turbo-stream',
    })
  }

  get orderedRoleIds() {
    return this.itemTargets.map((t) => t.dataset['planRoles-ReorderId'])
  }
}
