import { Controller } from '@hotwired/stimulus'
import { analyticsEnabled } from '../helpers/analytics_helpers'

export default class extends Controller {
  static values = {
    event: String,
    properties: Object,
    context: Object,
  }

  connect() {
    if (document.body.dataset['iframe']) {
      this.propertiesValue = { ...this.propertiesValue, iframe: true }
    }

    if (analyticsEnabled()) {
      analytics.trackLink(
        this.element,
        this.eventValue,
        this.propertiesValue,
        this.contextValue
      )
    }
  }
}
