import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'

export default class extends Controller {
  disconnect() {
    this.teardown()
  }

  show({ target }) {
    const referenceElement = document.getElementById(
      target.dataset['popperReference']
    )

    this.teardown()
    this.appendFrame(
      referenceElement,
      target.dataset['frameSrc'],
      target.dataset['frameId']
    )
    this.createPopper(referenceElement)
  }

  hide() {
    this.teardown()
  }

  // private

  async appendFrame(referenceElement, frameSrc, frameId) {
    this.frame = document.createElement('turbo-frame')

    this.element.append(this.frame)

    this.frame.id = frameId
    this.frame.src = frameSrc
    this.frame.classList.add('z-50')
    this.frame.style.width = `${
      referenceElement.getBoundingClientRect().width
    }px`

    await this.frame.loaded
  }

  createPopper(referenceElement) {
    this.popper = createPopper(referenceElement, this.frame, {
      placement: 'bottom-start',
    })
  }

  teardown() {
    this.frame?.remove()
    this.popper?.destroy()

    this.popper = undefined
    this.frame = undefined
  }
}
