import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import Tribute from 'tributejs'

export default class extends Controller {
  static values = {
    addParticipantUrl: String,
    fetchHubspotContactsUrl: String,
  }

  static targets = ['participantEmailInput']

  initialize() {
    this.tributeInstances = {}
    this.hubspotContactsLoaded = false
    this.hubspotContacts = []

    this.fetchHubSpotContacts = this.#fetchHubSpotContacts.bind(this)
    this.onSuggestionAccepted = this.#onSuggestionAccepted.bind(this)
  }

  participantEmailInputTargetConnected(target) {
    this.tributeInstances[target.id] = new Tribute({
      trigger: '',
      lookup: 'email',
      values: this.fetchHubSpotContacts,
      fillAttr: 'email',
      autocompleteMode: true,
      menuItemTemplate: this.#contactItemTemplate,
      noMatchTemplate: this.#noMatchTemplate,
    })
    this.tributeInstances[target.id].attach(target)

    target.addEventListener('tribute-replaced', this.onSuggestionAccepted)

    if (this.participantEmailInputTargets.length > 0) {
      this.element.querySelector('button[type="submit"]').disabled = false
    }
  }

  participantEmailInputTargetDisconnected(target) {
    this.tributeInstances[target.id].detach(target)
    target.removeEventListener('tribute-replaced', this.onSuggestionAccepted)

    if (this.participantEmailInputTargets.length === 0) {
      this.element.querySelector('button[type="submit"]').disabled = true
    }
  }

  addParticipant(event) {
    event.target.disabled = true

    const data = new FormData()
    data.append('index', event.params.index)

    get(this.addParticipantUrlValue, {
      query: data,
      responseKind: 'turbo-stream',
    })
  }

  removeParticipant(event) {
    event.target.closest('fieldset').remove()
  }

  #onSuggestionAccepted(event) {
    event.target.value = event.target.value.trim()
    const fieldset = event.target.closest('fieldset')
    const nameInput = fieldset.querySelector('input[name$="[name]"]')
    nameInput.value = event.detail.item.original.name
    nameInput.focus()
  }

  #fetchHubSpotContacts(_text, callback) {
    if (this.hubspotContactsLoaded) {
      callback(this.hubspotContacts)
    } else {
      fetch(this.fetchHubspotContactsUrlValue, {
        headers: { Accept: 'application/json' },
      })
        .then((response) => response.json())
        .then((data) => {
          this.hubspotContacts = data
          this.hubspotContactsLoaded = true
          callback(data)
        })
        .catch((_) => callback([]))
    }
  }

  #contactItemTemplate(item) {
    return `
      <div class="hover:bg-neutral-30 truncate group flex items-start gap-2 rounded-lg cursor-pointer">
        <div>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_89_64)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9313 15.9709C14.5871 15.9709 13.4968 14.8922 13.4968 13.5621C13.4968 12.2314 14.5871 11.1534 15.9313 11.1534C17.2761 11.1534 18.3658 12.2314 18.3658 13.5621C18.3658 14.8929 17.2761 15.9709 15.9313 15.9709ZM16.6603 8.92457V6.78129C17.226 6.51707 17.6213 5.95136 17.6213 5.29436V5.24486C17.6213 4.33843 16.8718 3.59722 15.9563 3.59722H15.9062C14.9908 3.59722 14.2412 4.33843 14.2412 5.24486V5.29436C14.2412 5.95136 14.6372 6.51707 15.2023 6.78129V8.92457C14.3608 9.05314 13.5919 9.39643 12.9574 9.90172L7.01227 5.32522C7.05148 5.17672 7.07912 5.02307 7.07977 4.86172C7.07977 3.83443 6.2402 3.00129 5.20262 3C4.1657 2.99872 3.32355 3.82929 3.32227 4.85657C3.32098 5.88322 4.16055 6.71572 5.19812 6.71764C5.53627 6.71764 5.84934 6.62314 6.12384 6.46822L11.9719 10.9714C11.4573 11.7374 11.1827 12.6394 11.1831 13.5621C11.1831 14.565 11.5033 15.4933 12.0446 16.2557L10.2651 18.0171C10.1224 17.9722 9.97379 17.9484 9.82412 17.9464C8.9717 17.9464 8.28127 18.6298 8.28127 19.4732C8.28127 20.3166 8.9717 21 9.82412 21C10.6766 21 11.3676 20.316 11.3676 19.4726C11.3657 19.3243 11.3416 19.1772 11.2963 19.0361L13.0551 17.2952C13.8825 17.9223 14.8924 18.2615 15.9306 18.2608C18.5535 18.2608 20.6788 16.1574 20.6788 13.5621C20.6788 11.2131 18.9347 9.27236 16.6596 8.92457" fill="#FF7A59"/>
            </g>
            <defs>
            <clipPath id="clip0_89_64">
            <rect width="18" height="18" fill="white" transform="translate(3 3)"/>
            </clipPath>
            </defs>
          </svg>
        </div>
        <div class="flex flex-col items-start">
          <span class="text-neutral-900 truncate" title="${
            item.original.email
          }">
            ${item.original.email}
          </span>
          ${(() => {
            if (item.original.name) {
              return `
                <span class="text-neutral-600 text-sm truncate">
                  ${item.original.name}
                </span>
              `
            } else {
              return ``
            }
          })()}
        </div
      </div>
    `
  }

  #noMatchTemplate() {
    return null
  }
}
