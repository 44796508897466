import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    autoOpen: { type: Boolean, default: false },
  }

  connect() {
    if (this.autoOpenValue) {
      this.open()
    }
  }

  open() {
    this.element.showModal()
  }

  close() {
    this.element.close()
  }
}
