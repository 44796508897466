import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    excludeButtons: String,
    excludeButtonGroups: String,
    minHeight: String,
    toolbarUniqueId: String,
  }

  connect() {
    this.trixCallback = this.onTrixInitialize.bind(this)
    this.element.addEventListener('trix-initialize', this.trixCallback)
  }

  disconnect() {
    this.element.removeEventListener('trix-initialize', this.trixCallback)
  }

  onTrixInitialize() {
    this.element.style.minHeight = this.minHeightValue || '75px'

    const excludeButtons =
      this.excludeButtonsValue || this.defaultExcludeButtons
    excludeButtons.split(',').forEach((button) => this.removeButton(button))

    const excludeButtonGroups =
      this.excludeButtonGroupsValue || this.defaultExcludeGroupButtons
    excludeButtonGroups
      .split(',')
      .forEach((buttonGroup) => this.removeButtonGroup(buttonGroup))
  }

  removeButton(trixAttribute) {
    this.toolbar
      .querySelector(`[data-trix-attribute="${trixAttribute}"]`)
      ?.remove()
  }

  removeButtonGroup(trixGroup) {
    this.toolbar
      .querySelector(`[data-trix-button-group="${trixGroup}"]`)
      ?.remove()
  }

  get toolbar() {
    if (this.toolbarUniqueIdValue) {
      return document.getElementById(this.toolbarUniqueIdValue)
    } else {
      return this.element.parentElement.querySelector('trix-toolbar')
    }
  }

  get defaultExcludeButtons() {
    return 'strike'
  }

  get defaultExcludeGroupButtons() {
    return 'block-tools,file-tools,history-tools'
  }
}
