import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['enabledButton', 'disabledButton']

  onEvent(event) {
    if (!(event.data.meetingBookSucceeded || event.data.meetingCreated)) {
      return
    }

    this.disabledButtonTarget.classList.add('hidden')
    this.enabledButtonTarget.classList.remove('hidden')
  }
}
