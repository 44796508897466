import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.timers = []
  }

  insertToken(event) {
    event.preventDefault()

    this.editor = this.#findEditor()
    this.editor.insertString(event.params.token)

    const button = event.currentTarget
    button.disabled = true

    this.timers.push(
      setTimeout(() => {
        button.disabled = false
      }, 2000)
    )
  }

  disconnect() {
    if (this.timers) {
      this.timers.forEach((timer) => {
        clearTimeout(timer)
      })
    }
  }

  #findEditor() {
    return (
      this.element.querySelector('trix-editor')?.editor ||
      this.element.parentElement.querySelector('trix-editor')?.editor
    )
  }
}
