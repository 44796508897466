export function menuItemTemplate(item) {
  return `
    <div class="hover:bg-neutral-30 truncate group flex items-center justify-between rounded-lg cursor-pointer">
      <div class="flex flex-grow truncate min-w-fit gap-0.5" title="${
        item.original.token
      }">
        <span class="text-neutral-400">{{ </span>
        <span class="px-0.5">${item.original.token}</span>
        <span class="text-neutral-400"> }}</span>
      </div>
      ${(() => {
        if (item.original.value) {
          return `
            <span class="text-neutral-400 pl-3 truncate">
              <span class="group-disabled:hidden">${item.original.value}</span>
            </span>
          `
        } else {
          return ``
        }
      })()}
    </div>
  `
}

export function noMatchTemplate() {
  return `
    <div class="px-2">
      <span class="text-sm text-neutral-400">No results found</span>
    </div>
  `
}
