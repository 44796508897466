import { Controller } from '@hotwired/stimulus'
import { Chart } from 'chart.js/auto'
import 'chartjs-adapter-date-fns'

export default class extends Controller {
  static values = {
    seriesData: Array,
  }

  connect() {
    let datasets = []
    for (const series of this.seriesDataValue) {
      datasets.push({
        data: series.data,
        label: series.label,
        borderColor: series.color,
        backgroundColor: series.color,
        showLine: true,
        tension: 0,
        pointRadius: 4,
      })
    }

    new Chart(this.element, {
      type: 'line',
      data: { datasets: datasets },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'week',
            },
            border: {
              display: false,
            },
            grid: {
              display: true,
            },
            title: {
              display: true,
              text: 'Week',
              color: 'rgba(0, 0, 0, 0.4)',
              font: {
                size: 12,
              },
              padding: {
                top: 16,
              },
            },
            ticks: {
              padding: 8,
            },
          },
          y: {
            title: {
              display: true,
              text: 'Percentage',
            },
            border: {
              display: false,
            },
            ticks: {
              padding: 12,
            },
            min: 0,
            max: 100
          },
        },
        plugins: {
          legend: {
            display: true,
          }
        },
      },
    })
  }
}
