export async function copyText(text) {
  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.writeText(text)
      return true
    } catch {
      // noop
    }
  }
  const textArea = createTextArea(text)
  document.body.append(textArea)
  const result = copyTextFromTextArea(textArea)
  document.body.removeChild(textArea)
  return result
}

export async function copyItems(items, fallbackText) {
  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.write(items)
      return true
    } catch {
      // noop
    }
  }

  return copyText(fallbackText)
}

function createTextArea(text) {
  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.opacity = 0
  textArea.style.position = 'absolute'
  textArea.style.left = '-9999px'
  return textArea
}

function copyTextFromTextArea(textArea) {
  textArea.select()
  return document.execCommand('copy')
}
