import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['message', 'messages', 'form', 'input']
  static values = { commentId: String }

  connect() {
    if (this.targetComment) {
      this.#scrollToTargetComment()
      this.#highlightTargetComment()
      this.#removeCommentIdFromUrl()
    } else if (this.inputTarget) {
      this.inputTarget.focus()
      const documentLength = this.inputTarget.editor
        .getDocument()
        .toString().length
      if (documentLength > 0) {
        this.inputTarget.editor.setSelectedRange(documentLength - 1)
      }
    }
  }

  submit() {
    this.formTarget.requestSubmit(this.submitterElement)
  }

  resetForm(event) {
    if (event.detail.success) {
      this.formTarget.querySelector('trix-editor').editor.loadHTML('')
    }
  }

  addIdToUrl({ params: { id } }) {
    const url = new URL(window.location)
    url.searchParams.set('comment_id', id)
    window.history.replaceState(null, '', url.toString())
  }

  // private

  messageTargetConnected(target) {
    const params = new URLSearchParams(window.location.search)
    const commentId = params.get('comment_id')
    const shouldScroll = !commentId || target.dataset['newComment']

    if (shouldScroll) this.scrollToBottom()
  }

  scrollToBottom() {
    this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight
  }

  #scrollToTargetComment() {
    this.targetComment.scrollIntoView({ behavior: 'auto', block: 'start' })
  }

  #highlightTargetComment() {
    this.targetComment.classList.add('bg-neutral-30')
  }

  #removeCommentIdFromUrl() {
    const url = new URL(window.location)
    url.searchParams.delete('comment_id')
    window.history.replaceState(null, '', url.toString())
  }

  get targetComment() {
    if (this.hasCommentIdValue) {
      return this.messageTargets.find((e) => e.id === this.commentIdValue)
    }
  }

  get submitterElement() {
    return this.formTarget.querySelector('[type=submit]')
  }
}
