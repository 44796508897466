import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class extends Controller {
  static targets = [
    'frame',
    'input',
    'option',
    'optionsContainer',
    'preview',
    'search',
    'spinner',
  ]
  static values = {
    appendable: Boolean, // Whether the dropdown supports adding options manually
    appendedOptions: Array, // Options added manually
    associatedStimulusCallbackAction: String, // Action to be called on the associatedStimulusController when an option is selected
    associatedStimulusController: String, // This controller can be optionally coupled to a stimulus controller for triggering actions and reading values
    associatedStimulusElementId: String, // Dom id for the element controlled by associatedStimulusController
    event: String, // Name of the custom event to emit upon selecting an option
    includeAppendables: Boolean, // Whether the dropdown should include options added manually
    loading: Boolean, // Whether the dropdown is fetching search results from the server
    searchUrl: String, // URL to hit when a query is typed into search input
    shouldForceOption: Boolean, // Whether the dropdown should assume the selected option for the user. E.g. when only one option can be selected.
    queryParams: Object, // Query params to include in search URL
  }

  initialize() {
    this.filterOptions = debounce(this.filterOptions.bind(this), 200)
  }

  connect() {
    this.inputTarget.classList.add('hidden')

    this.hideOptions()
    this.filterOptions()
  }

  trapEnter(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  toggleOptions() {
    if (this.optionsContainerTarget.classList.contains('hidden')) {
      this.showOptions()
    } else {
      this.hideOptions()
    }
  }

  clearFilter() {
    if (!this.hasSearchTarget) return

    this.searchTarget.value = ''
    this.searchTarget.focus()
    this.filterOptions()
  }

  async filterOptions() {
    this.retrieveAppendedOptions()

    if (this.hasFrameTarget) {
      this.frameTarget.src = this.frameUrl
      this.loadingValue = true

      await this.frameTarget.loaded

      this.loadingValue = false
    }

    this.onOptionsLoaded()
  }

  closeOnClickOutside({ target }) {
    if (this.element.contains(target)) return
    if (this.element.contains(document.activeElement)) return

    this.hideOptions()
  }

  select({ currentTarget }) {
    this.inputTarget.value = currentTarget.id

    this.setPreview()
    this.hideOptions()
    this.executeCallback()
    this.emitEvent()

    if (this.hasSearchTarget) {
      this.searchTarget.value = ''
    }
  }

  // Private methods:

  onOptionsLoaded() {
    if (this.selectedValue === '' && this.shouldForceOptionValue) {
      this.select({ currentTarget: this.optionTargets[0] })
    } else {
      this.setPreview()
    }
  }

  showOptions() {
    this.filterOptions()

    this.optionsContainerTarget.classList.remove('hidden')

    if (this.hasSearchTarget) {
      this.searchTarget.focus()
      this.searchTarget.select()
    }
  }

  hideOptions() {
    this.optionsContainerTarget.classList.add('hidden')
  }

  loadingValueChanged() {
    if (!this.hasSpinnerTarget) return

    if (this.loadingValue) {
      this.spinnerTarget.classList.add('flex')
      this.spinnerTarget.classList.remove('hidden')
    } else {
      this.spinnerTarget.classList.add('hidden')
      this.spinnerTarget.classList.remove('flex')
    }
  }

  setPreview() {
    if (!this.selectedOption) return

    const template = this.selectedOption.querySelector('template')

    if (template) {
      this.previewTarget.innerHTML = template.innerHTML
    } else {
      this.previewTarget.innerHTML = selectedItem.innerHTML
    }
  }

  appendOptionToForm(object) {
    if (this.associatedController) {
      this.associatedController.appendOption(object)
    }
  }

  retrieveAppendedOptions() {
    if (!this.includeAppendablesValue || !this.associatedController) return
    this.appendedOptionsValue = this.associatedController.appendedOptions
  }

  async appendOption(event) {
    const id = event.params['id']
    const currentTarget = { id }
    const label = event.params['label']

    event.preventDefault()
    this.appendOptionToForm({ id, label })
    this.retrieveAppendedOptions()
    this.select({ currentTarget })
    this.filterOptions()
  }

  // TODO: Deprecate
  executeCallback() {
    const label = this.selectedOption?.dataset['label']
    const name = this.selectedValue
    const shouldExecute =
      this.associatedController &&
      this.hasAssociatedStimulusCallbackActionValue &&
      label

    if (!shouldExecute) return

    this.associatedController[this.associatedStimulusCallbackActionValue]({
      label,
      name,
    })
  }

  emitEvent() {
    if (!this.eventValue) return
    const events = this.eventValue.split(' ')
    const detail = {
      ...this.selectedOption.dataset,
      value: this.selectedValue,
      id: this.selectedValue.id,
    }

    for (const event of events) {
      const customEvent = new CustomEvent(event, { bubbles: true, detail })
      this.element.dispatchEvent(customEvent)
    }
  }

  get selectedOption() {
    return this.optionTargets.find((e) => e.id === this.selectedValue)
  }

  get queryParams() {
    const appendedOptions = JSON.stringify(this.appendedOptionsValue)
    const query = this.hasSearchTarget ? this.searchTarget.value : null

    return {
      q: query,
      selected: this.selectedValue,
      appended_options: appendedOptions,
      forcedOption: this.forcedOption,
    }
  }

  get frameUrl() {
    const url = new URL(this.searchUrlValue)
    const params = url.searchParams

    params.set('q', this.queryParams['q'])
    params.set('selected', this.queryParams['selected'])

    if (this.includeAppendablesValue) {
      params.set('appended_options', this.queryParams['appended_options'])
    }

    if (this.appendableValue) {
      params.set('appendable', true)
    }

    if (this.shouldForceOptionValue) {
      params.set('forced_option', this.queryParams['forcedOption'])
    }

    for (const property in this.queryParamsValue) {
      params.set(property, this.queryParamsValue[property])
    }

    return url.toString()
  }

  get selectedValue() {
    return this.inputTarget.value
  }

  get associatedController() {
    const target = document.getElementById(
      this.associatedStimulusElementIdValue
    )
    const controller = this.associatedStimulusControllerValue

    if (!target || !controller) return

    return this.application.getControllerForElementAndIdentifier(
      target,
      controller
    )
  }

  get forcedOption() {
    if (this.associatedController) {
      return this.associatedController.forcedOption
    }
  }
}
