import '@oddbird/popover-polyfill'
import { getAnchoredPosition } from '@primer/behaviors'

const updateWhenVisible = (() => {
  const anchors = new Set()
  let intersectionObserver = null
  let resizeObserver = null

  function updateVisibleAnchors() {
    for (const anchor of anchors) {
      anchor.update()
    }
  }

  return (el) => {
    window.addEventListener('resize', updateVisibleAnchors)
    window.addEventListener('scroll', updateVisibleAnchors)

    intersectionObserver ||= new IntersectionObserver((entries) => {
      for (const entry of entries) {
        const target = entry.target
        if (entry.isIntersecting) {
          target.update()
          anchors.add(target)
        } else {
          anchors.delete(target)
        }
      }
    })

    resizeObserver ||= new ResizeObserver(() => {
      for (const anchor of anchors) {
        anchor.update()
      }
    })

    resizeObserver.observe(el.ownerDocument.documentElement)
    intersectionObserver.observe(el)
  }
})()

export default class AnchoredPositionElement extends HTMLElement {
  get align() {
    const value = this.getAttribute('align')
    if (value === 'center' || value === 'end') return value
    return 'start'
  }

  set align(value) {
    this.setAttribute('align', `${value}`)
  }

  get side() {
    const value = this.getAttribute('side')
    if (
      value === 'inside-top' ||
      value === 'inside-bottom' ||
      value === 'inside-left' ||
      value === 'inside-right' ||
      value === 'inside-center' ||
      value === 'outside-top' ||
      value === 'outside-left' ||
      value === 'outside-right'
    ) {
      return value
    }
    return 'outside-bottom'
  }

  set side(value) {
    this.setAttribute('side', `${value}`)
  }

  get anchorOffset() {
    const alias = this.getAttribute('anchor-offset')
    if (alias === 'spacious' || alias === '8') return 8
    return 4
  }

  // value=[number|'normal'|'spacious']
  set anchorOffset(value) {
    this.setAttribute('anchor-offset', `${value}`)
  }

  get anchor() {
    return this.getAttribute('anchor') || ''
  }

  set anchor(value) {
    this.setAttribute('anchor', `${value}`)
  }

  #anchorElement = null
  get anchorElement() {
    if (this.#anchorElement) return this.#anchorElement
    const idRef = this.anchor
    if (!idRef) return null
    return this.ownerDocument.getElementById(idRef)
  }

  set anchorElement(value) {
    this.#anchorElement = value
    if (!this.#anchorElement) {
      this.removeAttribute('anchor')
    }
  }

  get alignmentOffset() {
    return Number(this.getAttribute('alignment-offset'))
  }

  set alignmentOffset(value) {
    this.setAttribute('alignment-offset', `${value}`)
  }

  get allowOutOfBounds() {
    return this.hasAttribute('allow-out-of-bounds')
  }

  set allowOutOfBounds(value) {
    this.toggleAttribute('allow-out-of-bounds', value)
  }

  connectedCallback() {
    this.update()
    this.addEventListener('beforetoggle', () => this.update())
    updateWhenVisible(this)
  }

  static observedAttributes = [
    'align',
    'side',
    'anchor',
    'alignment-offset',
    'allow-out-of-bounds',
  ]
  attributeChangedCallback() {
    this.update()
  }

  #animationFrame
  update() {
    if (!this.isConnected) return
    cancelAnimationFrame(this.#animationFrame)

    this.#animationFrame = requestAnimationFrame(() => {
      const anchor = this.anchorElement
      this.classList.toggle('backdrop:bg-neutral-600', !anchor)

      if (anchor) {
        const { left, top } = getAnchoredPosition(this, anchor, this)
        this.style.top = `${top}px`
        this.style.left = `${left}px`
        this.style.bottom = 'auto'
        this.style.right = 'auto'
      } else {
        this.style.top = '0'
        this.style.left = '0'
        this.style.bottom = '0'
        this.style.right = '0'
      }
    })
  }
}

if (!customElements.get('anchored-position')) {
  window.AnchoredPositionElement = AnchoredPositionElement
  customElements.define('anchored-position', AnchoredPositionElement)
}
