import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['role']
  static values = { url: String, group: String }

  connect() {
    this.roleTargets.forEach((roleTarget) => {
      Sortable.create(roleTarget, {
        handle: '.sortable-handle',
        sort: false,
        forceFallback: true,
        group: {
          name: this.roleGroupName(roleTarget),
          put: this.roleGroups(roleTarget),
          pull: this.roleGroups(roleTarget),
        },
        filter: '.sortable-ignore',
        onAdd: (e) => {
          const isOccupied = this.isRoleOccupied(e.to)
          if (isOccupied) {
            const destinationRoleElements = this.getParticipantChildren(e.to)

            destinationRoleElements.forEach((dre) => {
              if (dre.dataset.id != e.item.dataset.id) {
                e.from.prepend(dre)
              }
            })
          }

          this.submitRoles()
        },
      })
    })
  }

  submitRoles() {
    const data = new FormData()
    this.roleTargets.forEach((role) => {
      this.getParticipantChildrenIds(role).forEach((id) => {
        data.append(`roles[${role.dataset.roleId}][]`, id)
      })
    })

    patch(this.urlValue, {
      body: data,
      responseKind: 'turbo-stream',
    })
  }

  isRoleOccupied(target) {
    return (
      target.dataset['roleId'] != 'participant' &&
      this.getParticipantChildren(target).length > 1
    )
  }

  getParticipantChildrenIds(target) {
    return this.getParticipantChildren(target).map((p) => p.dataset.id)
  }

  getParticipantChildren(target) {
    return Array.from(target.querySelectorAll('[data-role="draggable"]'))
  }

  roleGroups(target) {
    return this.roleTargets
      .filter((e) => e.dataset.roleId != target.dataset.roleId)
      .map((t) => this.roleGroupName(t))
  }

  roleGroupName(target) {
    return `${this.groupValue}-${target.dataset.roleId}`
  }
}
