import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: 'https://55051e60e507458e9f04777a3e093f98@o261130.ingest.sentry.io/5315985',

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  replaysOnErrorSampleRate: 1.0,

  // Only report errors in production
  enabled: process.env.NODE_ENV === 'production',

  initialScope: {
    user: {
      id: document.head.querySelector("meta[name='user-id']")?.content,
      email: document.head.querySelector("meta[name='user-email']")?.content,
    },
  },

  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /feedbacklane\.com/i,
  ],
})
