import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'
import hotkeys from 'hotkeys-js'

export default class extends Controller {
  static values = { identifier: String }
  static targets = ['container', 'backdrop', 'panel', 'frame']

  async connect() {
    const params = new URLSearchParams(window.location.search)
    const commentId = params.get('comment_id')
    const menu = params.get('menu')

    if (commentId && this.hasFrameTarget) {
      this.open()
    }

    if (menu && this.identifierValue == menu) {
      this.open()
    }
  }

  open() {
    this.addKeyboardShortcuts()

    this.containerTarget.classList.remove('hidden')
    enter(this.backdropTarget)
    enter(this.panelTarget)
  }

  close() {
    Promise.all([leave(this.backdropTarget), leave(this.panelTarget)]).then(
      () => {
        this.containerTarget.classList.add('hidden')
      }
    )

    this.removeKeyboardShortcuts()
  }

  clickOutside({ target }) {
    if (
      this.panelTarget.contains(target) ||
      this.panelTarget.contains(document.activeElement)
    ) {
      return
    }

    this.close()
  }

  disconnect() {
    this.removeKeyboardShortcuts()
  }

  addKeyboardShortcuts() {
    hotkeys('esc', () => this.close())
  }

  removeKeyboardShortcuts() {
    hotkeys.unbind('esc')
  }
}
