import { Controller } from '@hotwired/stimulus'
import Swiper from 'swiper/bundle'

export default class extends Controller {
  static targets = [
    'swiper',
    'swiperPrev',
    'swiperNext',
    'swiperPagination',
    'swiperDialog',
    'dialogPrev',
    'dialogNext',
    'dialogPagination',
  ]

  connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      pagination: {
        el: this.swiperPaginationTarget,
      },
      navigation: {
        nextEl: this.swiperNextTarget,
        prevEl: this.swiperPrevTarget,
      },
      rewind: true,
      grabCursor: true,
      keyboard: true,
      slidesPerView: '1.2',
      centeredSlides: true,
      spaceBetween: 30,
      slideToClickedSlide: true,
      on: {
        click: () => {
          this.swiperDialog.slideTo(this.swiper.activeIndex)
          this.dispatch('room:interaction', { prefix: false })
        },
      },
      controller: {
        control: this.swiperDialog,
      },
    })

    this.swiperDialog = new Swiper(this.swiperDialogTarget, {
      pagination: {
        el: this.dialogPaginationTarget,
      },
      navigation: {
        nextEl: this.dialogNextTarget,
        prevEl: this.dialogPrevTarget,
      },
      rewind: true,
      grabCursor: true,
      keyboard: true,
      centeredSlides: true,
      slideToClickedSlide: true,
      controller: {
        control: this.swiper,
      },
    })
  }

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy()
    }

    if (this.swiperDialog) {
      this.swiperDialog.destroy()
    }
  }
}
