import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['circle', 'percentage']
  static values = {
    stroke: Number,
    radius: Number,
    progress: Number,
  }

  connect() {
    this.onUpdate = this.onUpdate.bind(this)
    this.resetProgress()
    this.element.addEventListener('ui--progress:update', this.onUpdate)
  }

  disconnect() {
    this.element.removeEventListener('ui--progress:update', this.onUpdate)
  }

  onUpdate(event) {
    this.setProgress(event.detail.progress)
  }

  setProgress(progress) {
    const minProgress = Math.max(1, Math.trunc(progress))
    const normalizedRadius = this.radiusValue - this.strokeValue * 2
    const circumference = normalizedRadius * 2 * Math.PI
    const strokeDashoffset = circumference - (minProgress / 100) * circumference

    this.progressValue = minProgress
    this.percentageTarget.innerText = `${minProgress}%`
    this.circleTarget.setAttribute('stroke-dashoffset', strokeDashoffset)
  }

  resetProgress() {
    this.setProgress(0)
  }
}
