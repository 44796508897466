import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['item']
  static values = { url: String }

  connect() {
    Sortable.create(this.element, {
      handle: '.sort-handle',
      animation: 150,
      onUpdate: () => this.reorderItems(),
    })
  }

  reorderItems() {
    const data = new FormData()
    data.append('ids', this.orderedIds)

    patch(this.urlValue, {
      body: data,
      responseKind: 'turbo-stream',
    })
  }

  get orderedIds() {
    return this.itemTargets.map((t) => t.dataset.itemId)
  }
}
