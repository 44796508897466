import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  checkAll() {
    this.setAllCheckboxes(true)
  }

  checkNone() {
    this.setAllCheckboxes(false)
  }

  setAllCheckboxes(checked) {
    this.checkboxes.forEach((el) => {
      const checkbox = el

      if (!checkbox.disabled) {
        checkbox.checked = checked
      }
    })
  }

  get checkboxes() {
    return new Array(...this.element.querySelectorAll('input[type=checkbox]'))
  }
}
