import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link']

  initialize() {
    if (this.hasLinkTarget) this.linkTarget.hidden = true
  }

  async visit() {
    if (this.hasLinkTarget) this.linkTarget.click()
    if (this.frameElement) await this.frameElement.load
  }

  // Private methods:

  get frameElement() {
    const id =
      this.hasLinkTarget && this.linkTarget.getAttribute('data-turbo-frame')

    return id && document.getElementById(id)
  }
}
