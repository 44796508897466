import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  markToBeRemoved() {
    this.element.dataset['triggerVisible'] = false
  }

  onNewRowRemoved(_event) {
    this.element.remove()
  }

  onPersistedRowRemoved(_event) {
    this.element.querySelectorAll('input,select').forEach((input) => {
      input.removeAttribute('required')
    })

    this.element.classList.add('hidden')
    this.element.dataset['triggerVisible'] = false
  }
}
