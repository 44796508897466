import { Controller } from '@hotwired/stimulus'

VIEW_INTERNAL = 1000
FLUSH_INTERVAL = 1000 * 30
INACTIVE_INTERVAL = 1000 * 10
INACTIVE_THRESHOLD = 1000 * 5 * 60

export default class extends Controller {
  static values = {
    beaconUrl: String,
    debugMode: {
      type: Boolean,
      default: false,
    },
  }

  connect() {
    this.blockData = new Map()
    this.totalViewTime = 0
    this.timers = []
    this.trackingActive = false
    this.lastActivityAt = null

    this.#setupVisibilityObserver()
  }

  checkWindowState() {
    if (this.#pageNotActive()) {
      this.#debug('Page not active/focused')
      this.#stopTracking()
    } else {
      this.#debug('Page became active')
      this.#startTracking()
    }
  }

  #pageNotActive() {
    if (document.hidden) {
      this.#debug('Page hidden')
      return true
    }

    if (!document.hasFocus()) {
      // If an iframe is focused, the containing document does not
      // have focus, but in this case, keep considering the page active
      if (document.activeElement.tagName === 'IFRAME') {
        this.#debug('Page not focused, but iframe is active')
        return false
      }

      this.#debug('Page not focused')
      return true
    }
  }

  onActivity() {
    if (!this.lastActivityAt) {
      this.#debug('Page had user activity')
      this.#startTracking()
    }

    this.lastActivityAt = performance.now()
  }

  onInteraction(e) {
    const block = e.target.closest('[data-kind=block]')

    if (block) {
      const blockId = block.dataset.blockId
      let data = this.#getBlockData(blockId)
      data.interactions += 1
      this.blockData.set(blockId, data)

      this.#debug('Block interaction', blockId)
    }
  }

  #setupVisibilityObserver() {
    // Exclude the top portion of the viewport so blocks are made
    // invisible more quickly as they are scrolled out of view
    const observerOptions = {
      rootMargin: '-20% 0% 0% 0%',
      threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
    }
    this.blockObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // Consider a block visible if it is at least 30% visible
        // This was chosen after some testing, but is still arbitrary
        const isVisible = entry.intersectionRatio > 0.3

        this.#updateBlockVisibility(entry.target, isVisible)
      })
    }, observerOptions)

    this.visibleBlocks = new Set()
    document
      .querySelectorAll('[data-kind=block]')
      .forEach((block) => this.blockObserver.observe(block))
  }

  #updateBlockVisibility(element, isVisible) {
    const blockId = element.dataset.blockId

    if (isVisible) {
      this.visibleBlocks.add(blockId)
    } else {
      this.visibleBlocks.delete(blockId)
    }

    if (this.debugModeValue) {
      element.classList.toggle('ring-4', isVisible)
    }
  }

  #startTracking() {
    if (this.trackingActive) {
      this.#debug('Tried to start tracking but tracking is already active')
      return
    }
    if (this.#pageNotActive()) {
      this.#debug('Tried to start tracking but document is not active')
      return
    }

    this.trackingActive = true
    this.timers = [
      setInterval(this.#tallyViewTime.bind(this), VIEW_INTERNAL),
      setInterval(this.#flush.bind(this), FLUSH_INTERVAL),
      setInterval(this.#checkForInactivity.bind(this), INACTIVE_INTERVAL),
    ]
    this.#debug('Tracking started.')
  }

  #tallyViewTime() {
    const fullScreenBlockId = this.#findFullscreenBlock()
    if (fullScreenBlockId) {
      let data = this.#getBlockData(fullScreenBlockId)
      data.msViewed += VIEW_INTERNAL
      this.blockData.set(fullScreenBlockId, data)

      this.#debug('Tallying view time for fullscreen block', fullScreenBlockId)
    } else {
      this.visibleBlocks.forEach((blockId) => {
        let data = this.#getBlockData(blockId)
        data.msViewed += VIEW_INTERNAL
        this.blockData.set(blockId, data)
      })

      this.#debug('Tallying view time for all visible blocks')
    }

    this.totalViewTime += VIEW_INTERNAL
    this.#debug('Tallying total view time')
  }

  #findFullscreenBlock() {
    const openDialog = document.querySelector('dialog[open]')
    if (!openDialog) {
      return null
    }

    return openDialog.closest('[data-kind=block]')?.dataset?.blockId
  }

  #flush() {
    const formData = new FormData()
    var param = document
      .querySelector('meta[name=csrf-param]')
      .getAttribute('content')
    var token = document
      .querySelector('meta[name=csrf-token]')
      .getAttribute('content')
    formData.append(param, token)

    formData.append('view_time', this.totalViewTime)
    this.totalViewTime = 0

    this.blockData.forEach((data, blockId) => {
      if (data.msViewed > 0) {
        formData.append(`block_ids[${blockId}]`, data.msViewed)
        data.msViewed = 0
      }

      if (data.interactions > 0) {
        formData.append(`block_interactions[${blockId}]`, data.interactions)
        data.interactions = 0
      }

      this.blockData.set(blockId, data)
    })

    if (navigator.sendBeacon && this.hasBeaconUrlValue) {
      try {
        navigator.sendBeacon(this.beaconUrlValue, formData)
      } catch (error) {
        this.#debug('Failed to send beacon', error)
      }
    }

    this.#debug('Flushing tracked data')
  }

  #checkForInactivity() {
    const timeSinceLastActivity = performance.now() - this.lastActivityAt

    if (timeSinceLastActivity > INACTIVE_THRESHOLD) {
      this.#debug('Page went inactive')
      this.#stopTracking()
    }
  }

  #stopTracking() {
    this.#flush()
    this.timers.forEach(clearInterval)
    this.timers = []
    this.trackingActive = false
    this.lastActivityAt = null
    this.#debug('Tracking stoppped.')
  }

  disconnect() {
    this.#stopTracking()
    this.#teardownVisibilityObserver()
  }

  #teardownVisibilityObserver() {
    this.blockObserver?.disconnect()
  }

  #debug(message) {
    if (this.debugModeValue) {
      console.log(`[BLOCK VIEWING] ${message}`)
    }
  }

  #getBlockData(blockId) {
    let data = this.blockData.get(blockId)

    if (!data) {
      data = { msViewed: 0, interactions: 0 }
    }

    return data
  }
}
