import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['searchInput', 'searchableItemsRoot']
  static values = {
    grouped: Boolean,
  }

  connect() {
    this.element.scrollIntoView()
    if (this.hasSearchInputTarget) {
      this.searchInputTarget.focus()
    }
  }

  onSearch(event) {
    const searchValue = event.target.value.toLowerCase()

    if (searchValue.length === 0) {
      this.showAllItems()
      return
    }

    if (this.groupedValue) {
      this.searchInGroups(searchValue)
    } else {
      this.searchInItems(searchValue)
    }
  }

  showAllItems() {
    if (this.sliptBetweenGroupsValue) {
      this.searchableItemsRootTarget
        .querySelectorAll('[data-searchable-group]')
        .forEach((group) => {
          this.showItem(group)

          group
            .querySelectorAll('[data-searchable-item-value]')
            .forEach((item) => this.showItem(item))
        })
    } else {
      this.searchableItemsRootTarget
        .querySelectorAll('[data-searchable-item-value]')
        .forEach((item) => this.showItem(item))
    }
  }

  showItem(item) {
    item.classList.remove('hidden')
    item.classList.add('flex')
  }

  hideItem(item) {
    item.classList.remove('flex')
    item.classList.add('hidden')
  }

  searchInGroups(searchValue) {
    this.searchableItemsRootTarget
      .querySelectorAll('[data-searchable-group]')
      .forEach((group) => {
        const groupTitle = group.dataset.searchableGroup.toLowerCase()
        const items = group.querySelectorAll('[data-searchable-item-value]')

        const groupMatch = groupTitle.includes(searchValue)

        let groupItemsHiddenCount = 0
        items.forEach((item) => {
          const itemValue = item.dataset.searchableItemValue.toLowerCase()
          const itemMatch = itemValue.includes(searchValue)

          if (groupMatch || itemMatch) {
            this.showItem(group)
            this.showItem(item)
          } else {
            this.hideItem(item)
            groupItemsHiddenCount++
          }
        })

        if (groupItemsHiddenCount === items.length) {
          this.hideItem(group)
        }
      })
  }

  searchInItems(searchValue) {
    this.searchableItemsRootTarget
      .querySelectorAll('[data-searchable-item-value]')
      .forEach((item) => {
        const itemValue = item.dataset.searchableItemValue.toLowerCase()
        const match = itemValue.includes(searchValue)

        if (match) {
          this.showItem(item)
        } else {
          this.hideItem(item)
        }
      })
  }
}
