import { Controller } from '@hotwired/stimulus'
import DropzoneDirectUploadsController from '../../activestorage/dropzone_direct_uploads_controller'

export default class extends Controller {
  static targets = ['label', 'progress', 'file']
  static values = { singleFileMessage: String }

  connect() {
    this.onStartUploads = this.onStartUploads.bind(this)
    this.onEndUploads = this.onEndUploads.bind(this)
    this.progressControllerElement = this.progressTarget.querySelector(
      '[data-controller="ui--progress"]'
    )
    this.form.addEventListener('direct-uploads:start', this.onStartUploads)
    this.form.addEventListener('direct-uploads:end', this.onEndUploads)
    this.resetMultipleFilesProgressControl()
  }

  disconnect() {
    this.form.removeEventListener('direct-uploads:start', this.onStartUploads)
    this.form.removeEventListener('direct-uploads:end', this.onEndUploads)
  }

  onFileInputChange(event) {
    this.setFilesBeingUploadedTotalSize(event.target.files)
  }

  onStartUploads(_event) {
    this.labelTarget.classList.add('hidden')
    this.progressTarget.classList.add('flex')
    this.progressTarget.classList.remove('hidden')
    this.element.classList.remove('border', 'bg-white')
    this.element.classList.add('bg-neutral-50')
  }

  onEndUploads(_event) {
    this.element.classList.add('border', 'bg-white')
    this.element.classList.remove('bg-neutral-50')
    this.labelTarget.classList.remove('hidden')
    this.progressTarget.classList.remove('flex')
    this.progressTarget.classList.add('hidden')
    this.fileTarget.removeAttribute('disabled')
    this.element
      .querySelectorAll(`input[type='hidden'][name='${this.fileTarget.name}'`)
      .forEach((input) => {
        input.remove()
      })
    this.form.reset()
    this.updateProgress(0)
    this.resetMultipleFilesProgressControl()
  }

  onEndUpload(event) {
    const percentualFractionOfTotalSize =
      (100 / this.totalSizeFilesUploading) * event.detail.file.size
    this.totalPercentageAlreadyUploadedFiles += percentualFractionOfTotalSize
  }

  onProgress(event) {
    const percentualFractionOfTotalSize =
      (100 / this.totalSizeFilesUploading) * event.detail.file.size
    const currentFileOverallProgress =
      (percentualFractionOfTotalSize / 100) * event.detail.progress
    const newProgress =
      this.totalPercentageAlreadyUploadedFiles + currentFileOverallProgress
    this.updateProgress(newProgress)
  }

  onDragEnter(event) {
    this.preventDefaultBehaviorAndStopPropagation(event)

    if (this.form.hasAttribute(this.processingAttribute)) {
      return
    }

    this.highlightDropzone()
  }

  onDragOver(event) {
    this.preventDefaultBehaviorAndStopPropagation(event)

    if (this.form.hasAttribute(this.processingAttribute)) {
      return
    }

    this.highlightDropzone()
  }

  onDragLeave(event) {
    this.preventDefaultBehaviorAndStopPropagation(event)

    if (this.form.hasAttribute(this.processingAttribute)) {
      return
    }

    this.unhighlightDropzone()
  }

  onDrop(event) {
    this.preventDefaultBehaviorAndStopPropagation(event)

    if (this.form.hasAttribute(this.processingAttribute)) {
      return
    }

    this.unhighlightDropzone()

    const files = event.dataTransfer.files
    if (files.length > 1 && !this.fileTarget.multiple) {
      alert(
        this.singleFileMessageValue || 'You can only upload one file at a time.'
      )
      return
    }

    this.setFilesBeingUploadedTotalSize(files)

    const uploadsController = new DropzoneDirectUploadsController(
      this.form,
      this.fileTarget,
      files
    )

    this.form.setAttribute(this.processingAttribute, '')
    this.fileTarget.disabled = true
    uploadsController.start((error) => {
      this.form.removeAttribute(this.processingAttribute)
      if (error) {
        this.fileTarget.disabled = false
      } else {
        this.form.requestSubmit()
        Turbo.cache.clear()
      }
    })
  }

  preventDefaultBehaviorAndStopPropagation(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  highlightDropzone() {
    this.element.classList.remove('bg-white', 'border-neutral-200')
    this.element.classList.add('bg-neutral-100', 'border-neutral-400')
  }

  unhighlightDropzone() {
    this.element.classList.remove('bg-neutral-100', 'border-neutral-400')
    this.element.classList.add('bg-white', 'border-neutral-200')
  }

  setFilesBeingUploadedTotalSize(files) {
    this.totalSizeFilesUploading = Array.from(files).reduce(
      (total, file) => total + file.size,
      0
    )
  }

  resetMultipleFilesProgressControl() {
    this.totalSizeFilesUploading = 0
    this.totalPercentageAlreadyUploadedFiles = 0
  }

  updateProgress(newProgress) {
    this.progressControllerElement.dispatchEvent(
      new CustomEvent('ui--progress:update', {
        detail: { progress: newProgress },
      })
    )
  }

  get form() {
    return this.element.closest('form')
  }

  get processingAttribute() {
    return 'data-direct-uploads-processing'
  }
}
