import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static outlets = ['tasks--action-bar']
  static targets = ['submission']

  static values = {
    disabledMarkDoneTooltip: String,
  }

  connect() {
    this.checkForMissingSubmissions()
  }

  submissionTargetConnected() {
    this.checkForMissingSubmissions()
  }

  submissionTargetDisconnected() {
    this.checkForMissingSubmissions()
  }

  checkForMissingSubmissions() {
    // Find all children file request elements
    const fileRequests = this.element.querySelectorAll('[data-file-request]')

    if (fileRequests.length === 0 || !this.hasTasksActionBarOutlet) {
      return
    }

    // Find if any of the file requests are missing files
    const missingSubmissionOnRequired = Array.from(fileRequests).some(
      (request) => {
        // Find the submissions sub-element
        const submissions = request.querySelector('#submissions_' + request.id)
        // Check if there are any submissions. The selector targets file submission elements only.
        const submissionTargets = submissions.querySelector(
          '[data-tasks--actions--file-request-action-target="submission"]'
        )
        // If the submission is required and there are no submissions, return true
        return request.dataset.required == 'true' && !submissionTargets
      }
    )

    // Enable / Disable submit button based on missing submissions
    if (missingSubmissionOnRequired) {
      this.tasksActionBarOutlet.syncMarkDoneButton({
        disable: true,
        tooltip: {
          show: true,
          content: this.disabledMarkDoneTooltipValue,
        },
      })
    } else {
      this.tasksActionBarOutlet.syncMarkDoneButton({
        disable: false,
        tooltip: { show: false },
      })
    }
  }
}
