import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'blank', 'copyTemplate', 'templateFields']
  static classes = ['enabledBg', 'disabledBg']

  connect() {
    this.sync()
  }

  selectBlank() {
    this.inputTarget.value = true
    this.sync()
  }

  selectTemplate() {
    this.inputTarget.value = false
    this.sync()
  }

  sync() {
    if (this.inputTarget.value === 'true') {
      this.templateFieldsTarget.disabled = true
      this.blankTarget.classList.remove(this.disabledBgClass)
      this.blankTarget.classList.add(this.enabledBgClass)
      this.copyTemplateTarget.classList.add(this.disabledBgClass)
      this.copyTemplateTarget.classList.remove(this.enabledBgClass)
    } else {
      this.templateFieldsTarget.disabled = false
      this.blankTarget.classList.add(this.disabledBgClass)
      this.blankTarget.classList.remove(this.enabledBgClass)
      this.copyTemplateTarget.classList.remove(this.disabledBgClass)
      this.copyTemplateTarget.classList.add(this.enabledBgClass)
    }
  }
}
