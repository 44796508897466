import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['destination', 'source']

  apply() {
    this.destinationTarget.editor.loadHTML(this.sourceTarget.innerHTML)
    this.destinationTarget.focus()
  }
}
