import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    fontSize: String,
    widthWithoutText: Number
  }

  initialize() {
    this.canvas = document.createElement("canvas");
  }

  connect() {
    this.resize()
  }

  resize() {
    const fontSize = this.getTextWidth(this.element.selectedOptions[0].text, this.getCanvasFont(this.element));
    this.element.style.width = `${fontSize + this.widthWithoutTextValue}px`;
  }

  getTextWidth(text, font) {
    const context = this.canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  getCssStyle(element, prop) {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
  }

  getCanvasFont(el = document.body) {
    const fontWeight = this.getCssStyle(el, "font-weight") || "normal";
    const fontSize = this.getCssStyle(el, "font-size") || this.fontSizeValue;
    const fontFamily = this.getCssStyle(el, "font-family") || "Inter var";

    return `${fontWeight} ${fontSize} ${fontFamily}`;
  }
}
