import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['option']
  static classes = ['checked']

  connect() {
    this.highlightChecked()
  }

  check(event) {
    event.preventDefault()
    event.currentTarget.querySelector("input[type='radio']").checked = true
    this.highlightChecked()
  }

  reset() {
    this.optionTargets[0].querySelector("input[type='radio']").checked = true
    this.highlightChecked()
  }

  highlightChecked() {
    this.optionTargets.forEach((t) => {
      if (t.querySelector("input[type='radio']").checked) {
        t.classList.add(...this.checkedClasses)
      } else {
        t.classList.remove(...this.checkedClasses)
      }
    })
  }
}
