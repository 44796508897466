import { Controller } from '@hotwired/stimulus'
import { Chart } from 'chart.js/auto'
import 'chartjs-adapter-date-fns'

export default class extends Controller {
  static values = {
    data: Array,
  }

  connect() {
    var dataPoints = this.dataValue

    this.chart = new Chart(this.element, {
      type: 'line',
      data: {
        datasets: [
          {
            data: dataPoints,
            fill: true,
            backgroundColor: '#FDBEB9',
            borderColor: '#FB7D74',
            pointRadius: 0,
            tension: 0.3,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        layout: {
          padding: {
            top: 12,
          },
        },
        interaction: {
          intersect: false,
          mode: 'nearest',
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
            },
            border: {
              display: false,
            },
            grid: {
              drawOnChartArea: false,
            },
            ticks: {
              padding: 8,
            },
          },
          y: {
            display: false,
            min: 0,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            external: externalTooltipHandler,
          },
        },
      },
      plugins: [
        {
          id: 'customCanvasBackgroundColor',
          beforeDraw: (chart, args, options) => {
            const { ctx } = chart
            ctx.save()
            ctx.globalCompositeOperation = 'destination-over'
            ctx.fillStyle = '#fff'
            ctx.fillRect(0, 0, chart.width, chart.height)
            ctx.restore()
          },
        },
      ],
    })
  }

  disconnect() {
    if (this.chart) {
      this.chart.destroy()
    }
  }
}

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.background = '#222'
    tooltipEl.style.borderRadius = '8px'
    tooltipEl.style.color = 'white'
    tooltipEl.style.opacity = 1
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transition = 'all .1s ease'
    tooltipEl.style.zIndex = 10
    tooltipEl.style.margin = '12px'
    tooltipEl.style.padding = '6px 8px'

    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context
  const tooltipEl = getOrCreateTooltip(chart)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  } else {
    tooltipEl.style.opacity = 1
  }

  const dataPoint = tooltip.dataPoints[0].raw

  // Set Text
  tooltipEl.innerHTML = `
    <div class="flex flex-col gap-2">
      <span class="text-sm text-white font-medium">${dataPoint.date}</span>

      <div class="flex flex-col gap-1">
        <span class="text-xs text-white/60 whitespace-nowrap">
          <span class="text-white px-1 py-0.5 bg-white/20 rounded">${
            dataPoint.duration
          }</span> total view time
        </span>
        <span class="text-xs text-white/60 whitespace-nowrap ${
          dataPoint.clicks > 0 ? '' : 'hidden'
        }">
          <span class="text-white px-1 py-0.5 bg-white/20 rounded">${
            dataPoint.clicks
          }</span> clicks
        </span>
      </div>
    </div>
  `

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

  if (tooltip.caretX >= chart.width - 150) {
    tooltipEl.style.left =
      positionX + tooltip.caretX - tooltipEl.clientWidth + 'px'
  } else {
    tooltipEl.style.left = positionX + tooltip.caretX + 'px'
  }

  tooltipEl.style.top = positionY + tooltip.caretY + 'px'
}
