import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String,
  }

  connect() {
    this.counter = 0
    this.timer = setTimeout(() => this.#poll(), 500)
  }

  disconnect() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  #poll() {
    this.counter += 1

    if (this.counter > 15) {
      console.error('Room setup timed out')
      return
    }

    fetch(this.urlValue)
      .then((response) => response.json())
      .then((data) => {
        if (data.ready) {
          Turbo.visit(data.path, { action: 'replace' })
        } else {
          this.timer = setTimeout(() => this.#poll(), 1500)
        }
      })
  }
}
