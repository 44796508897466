import { Controller } from '@hotwired/stimulus'
import { get, patch } from '@rails/request.js'
import playerjs from 'player.js'

const IFRAMELY_API = 'https://cdn.iframe.ly/api/iframely'

export default class extends Controller {
  static targets = ['link']
  static values = { key: String, playUrl: String, videoUrl: String }

  async connect() {
    const params = new URLSearchParams()
    params.append('url', this.videoUrlValue)
    params.append('key', this.keyValue)
    params.append('playerjs', 1)

    const response = await get(IFRAMELY_API, { query: params })
    if (!response.ok) {
      return this.showError()
    }

    const body = await response.json
    if (body.meta.medium !== 'video') {
      return this.showError()
    }

    this.element.innerHTML = body.html

    const iframe = this.element.querySelector('iframe')
    if (!iframe) {
      return this.showError()
    }

    const player = new playerjs.Player(iframe)
    player.on('play', () => this.markPlayed())
  }

  markPlayed() {
    patch(this.playUrlValue)
  }

  showError() {
    this.element.innerHTML = `Unable to fetch video: ${this.videoUrlValue}`
    console.warn('Unable to fetch video')
  }

  disconnect() {
    this.element.innerHTML = ''
  }
}
