import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  enable() {
    this.inputTargets.forEach((c) => (c.disabled = false))
  }

  disable() {
    this.inputTargets.forEach((c) => (c.disabled = true))
  }
}
