import { Controller } from '@hotwired/stimulus'
import { analyticsEnabled } from '../helpers/analytics_helpers'

export default class extends Controller {
  static values = {
    event: String,
    properties: Object,
    context: Object,
  }

  // If tracking a link click use track_link_controller.js instead
  connect() {
    if (document.body.dataset['iframe']) {
      this.propertiesValue = { ...this.propertiesValue, iframe: true }
    }

    if (analyticsEnabled()) {
      analytics.track(
        this.eventValue,
        this.propertiesValue,
        this.contextValue,
        null
      )
    }
  }
}
