import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'item']
  static classes = ['active']

  connect() {
    this.tabTargets.forEach((tab) => {
      if (tab.dataset.active === 'true') {
        tab.classList.add(...this.activeClasses)
        tab.click()
      }
    })
  }

  select(event) {
    const selectedIndex = event.params.index

    this.tabTargets.forEach((tab, idx) => {
      if (idx === selectedIndex) {
        tab.classList.add(...this.activeClasses)
      } else {
        tab.classList.remove(...this.activeClasses)
      }
    })

    this.itemTargets.forEach((item, idx) => {
      item.classList.toggle('hidden', idx !== selectedIndex)
    })
  }
}
