import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['bar', 'row']

  highlight(e) {
    const phaseId = e.currentTarget.dataset.phaseId

    this.rowTargets.forEach((row) => {
      if (row.dataset.phaseId === phaseId) {
        row.classList.add('bg-neutral-30')
      }
    })

    this.barTargets.forEach((bar) => {
      if (bar.dataset.phaseId === phaseId) {
        bar.classList.add('h-6')
      }
    })
  }

  unhighlight(e) {
    const phaseId = e.currentTarget.dataset.phaseId

    this.rowTargets.forEach((row) => {
      if (row.dataset.phaseId === phaseId) {
        row.classList.remove('bg-neutral-30')
      }
    })

    this.barTargets.forEach((bar) => {
      if (bar.dataset.phaseId === phaseId) {
        bar.classList.remove('h-6')
      }
    })
  }
}
