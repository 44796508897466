import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // Input targets need to have a data-label-text attribute to compose the
  // CSV string sent to the back-end. For more information, please see
  // https://github.com/ArrowsHQ/Arrows/pull/3727.
  static targets = ['input', 'output']

  connect() {
    this.calculate()
  }

  calculate() {
    this.outputTarget.value = this.inputTargets
      .filter((c) => c.checked)
      .map((c) => c.dataset.labelText)
      .join(',')

    this.outputTarget.dispatchEvent(new Event('change'))
  }
}
