import { DropzoneDirectUploadController } from './dropzone_direct_upload_controller'

export default class DropzoneDirectUploadsController {
  constructor(form, input, files) {
    this.form = form
    this.input = input
    this.files = files
  }

  start(callback) {
    const controllers = this.createDirectUploadControllers()

    const startNextController = () => {
      const controller = controllers.shift()
      if (controller) {
        controller.start((error) => {
          if (error) {
            callback(error)
            this.dispatch('end')
          } else {
            startNextController()
          }
        })
      } else {
        callback()
        this.dispatch('end')
      }
    }

    this.dispatch('start')
    startNextController()
  }

  createDirectUploadControllers() {
    const controllers = []

    Array.from(this.files).forEach((file) => {
      const controller = new DropzoneDirectUploadController(this.input, file)
      controllers.push(controller)
    })

    return controllers
  }

  dispatch(name, detail = {}) {
    const customEvent = new CustomEvent(`direct-uploads:${name}`, {
      bubbles: true,
      cancelable: true,
      detail,
    })
    return this.form.dispatchEvent(customEvent)
  }
}
