import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['item']
  static values = {
    url: String,
    group: { type: String, default: 'plan' },
  }

  connect() {
    Sortable.create(this.element, {
      group: {
        name: this.groupValue,
        pull: (to, from, el) => {
          // Only allow tasks to be "pulled" from one phase
          // to another. This prevents gates from being moved
          // to a different phase
          return el.dataset.itemKind === 'task'
        },
      },
      handle: '.plan-sort-handle',
      animation: 150,
      filter: '.sortable-ignore',
      onUpdate: (e) => this.reorderItems(e),
      onAdd: (e) => this.reorderItems(e),
    })
  }

  reorderItems(e) {
    if (e.pullMode === 'clone') {
      return
    }

    const data = new FormData()
    if (this.hasGroupValue) {
      data.append('group', this.groupValue)
    }
    this.itemTargets.map((t) => {
      data.append('items[]', `${t.dataset.itemKind}:${t.dataset.itemId}`)
    })
    data.append('moved_item_kind', e.item.dataset.itemKind)
    data.append('moved_item_id', e.item.dataset.itemId)

    if (e.to && e.to.dataset.phaseId) {
      data.append('phase_id', e.to.dataset.phaseId)

      if (e.to !== e.from) {
        data.append('move_phase', true)
      }
    }

    patch(this.urlValue, {
      body: data,
      responseKind: 'turbo-stream',
    })
  }
}
