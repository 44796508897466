import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { menuId: String }

  connect() {
    this.toggle = this.toggle.bind(this)

    this.menuElement = this.element.querySelector(`#${this.menuIdValue}`)
    if (this.menuElement) {
      this.menuElement.addEventListener('toggle', this.toggle)
    }
  }

  disconnect() {
    this.menuElement = this.element.querySelector(`#${this.menuIdValue}`)
    if (this.menuElement) {
      this.menuElement.removeEventListener('toggle', this.toggle)
    }
  }

  toggle(event) {
    this.element.dataset.menuOpen = event.target.open ? 'true' : 'false'
  }
}
