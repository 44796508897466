import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    // Waits for remote tailwind library to load so scrollHeight is calculated
    // on actual height of calculated styles
    setTimeout(() => {
      this.inputTargets.forEach((el) => {
        let savedValue = el.value
        el.value = null
        el.style.minHeight = `${el.scrollHeight}px`
        el.value = savedValue

        el.style.resize = 'none'
        el.style.scrollbarWidth = 'none'
        el.style.boxSizing = 'border-box'
        el.style.overflow = 'hidden'

        if (savedValue.length > 0) {
          this.resize(el)
        }
      })
    }, 150)
  }

  noReturns(event) {
    event.target.value = event.target.value.replace(/(\r\n|\n|\r)/g, '')
    this.resize(event.target)
  }

  change(event) {
    this.resize(event.target)
  }

  resize(el) {
    el.style.height = 'auto'
    el.style.height = `${el.scrollHeight + 2}px`
  }

  syncInputs() {
    this.inputTargets.forEach((e) => this.resize(e))
  }
}
