import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['child']

  connect() {
    this.sync()
  }

  sync() {
    this.childTargets.forEach((c) => {
      c.disabled = !this.optionEnabled(c.dataset.enabledBy)
    })
  }

  optionEnabled(value) {
    const option = this.element.querySelector(`input[value=${value}]`)

    return option.selected || option.checked
  }
}
