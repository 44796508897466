import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.signalHubspotModalToClose()
  }

  signalHubspotModalToClose() {
    // https://legacydocs.hubspot.com/docs/methods/crm-extensions/crm-extensions-overview#iframe-actions
    window.parent.postMessage(JSON.stringify({ action: 'DONE' }), '*')
  }
}
