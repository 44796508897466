import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['example']
  static values = {
    url: String,
  }

  connect() {
    this.exampleTargets.forEach((target) => {
      Sortable.create(target, {
        sort: false,
        forceFallback: true,
        revertOnSpill: true,
        group: {
          name: 'within-phases',
          pull: 'clone',
          put: false,
        },
        filter: '.sortable-ignore',
        onRemove: (e) => this.insertItem(e),
      })
    })
  }

  insertItem(event) {
    event.item.id = 'temp-task-' + Date.now() + '-' + Math.random()

    const data = new FormData()
    data.append('temp_id', event.item.id)
    data.append('task_id', event.item.dataset.taskId)
    data.append('phase_id', event.to.dataset.phaseId)
    data.append('position', event.newIndex)

    post('/tasks_imports', {
      body: data,
      responseKind: 'turbo-stream',
    })
  }
}
