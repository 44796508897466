import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['newTriggerElement', 'trigger']

  addNew(event) {
    post(event.target.dataset.newTriggerUrl, { responseKind: 'turbo-stream' })
  }

  onTriggerRemoved(_event) {
    const visibleTrigger = this.triggerTargets.find(
      (el) => el.dataset['triggerVisible'] == 'true'
    )

    if (visibleTrigger) {
      const whenLabel = visibleTrigger.querySelector('[data-when-label]')
      whenLabel.classList.add('flex')
      whenLabel.classList.remove('hidden')
    } else {
      this.newTriggerElementTargets.forEach((el) => {
        if (el.dataset['newTriggerUrl']) {
          const elUrl = new URL(
            el.dataset['newTriggerUrl'],
            document.location.origin
          )

          elUrl.searchParams.set('no_visible_triggers', 'true')
          el.dataset['newTriggerUrl'] = elUrl.pathname + elUrl.search
        } else {
          const elUrl = new URL(el.href, document.location.origin)

          elUrl.searchParams.set('no_visible_triggers', 'true')
          el.href = elUrl.pathname + elUrl.search
        }
      })
    }
  }
}
