import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'iframelyMessage', 'nativeMessage']

  connect() {
    this.sync()
  }

  sync() {
    if (this.hasSourceTarget && this.sourceTarget.value.match(/hsforms\.com/)) {
      this.nativeMessageTarget.classList.remove('hidden')
      this.iframelyMessageTarget.classList.add('hidden')
    } else {
      this.nativeMessageTarget.classList.add('hidden')
      this.iframelyMessageTarget.classList.remove('hidden')
    }
  }
}
