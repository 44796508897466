import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.element.addEventListener('submit', this.submit.bind(this))
  }

  submit(event) {
    let invalidInputs = false

    this.inputTargets.forEach((input) => {
      if (input.value.trim().length < 1) {
        invalidInputs = true

        tippy(input, {
          placement: 'top-start',
          content: 'Please fill this field',
          trigger: 'manual',
          showOnCreate: true,
          hideOnClick: true,
          onShow: (instance) => {
            setTimeout(() => {
              instance.hide()
            }, 800)
          },
        })
      }
    })

    if (invalidInputs) {
      event.preventDefault()
    }
  }
}
