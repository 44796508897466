import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  plus() {
    this.setValue(this.currentValue + 1)
  }

  minus() {
    this.setValue(this.currentValue - 1)
  }

  setValue(value) {
    this.inputTarget.value = this.clamp(value)
    this.inputTarget.dispatchEvent(new Event('change'))
  }

  clamp(value) {
    return Math.min(Math.max(value, this.minValue), this.maxValue)
  }

  forceNumber() {
    if (!parseInt(this.inputTarget.value)) {
      this.inputTarget.value = 0
      this.select()
    }
  }

  select() {
    this.inputTarget.select()
  }

  get currentValue() {
    return parseInt(this.inputTarget.value) || 0
  }

  get minValue() {
    return this.inputTarget?.min || -Infinity
  }

  get maxValue() {
    return this.inputTarget?.max || Infinity
  }
}
