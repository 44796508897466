import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'switch',
    'propertyPickerFieldset',
    'propertyObjectTypeSelect',
    'propertyPickerSelectFieldset',
  ]

  toggle() {
    this.propertyPickerFieldsetTarget.classList.toggle('hidden')
    this.propertyPickerFieldsetTarget.disabled = !this.switchTarget.checked
  }

  objectTypeChanged() {
    this.propertyPickerSelectFieldsetTargets.forEach((fieldset) => {
      if (
        fieldset.dataset.objectType == this.propertyObjectTypeSelectTarget.value
      ) {
        fieldset.classList.remove('hidden')
        fieldset.disabled = false
      } else {
        fieldset.classList.add('hidden')
        fieldset.disabled = true
      }
    })
  }
}
