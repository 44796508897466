import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['switch', 'slider']

  connect() {
    this.syncView()
  }

  syncView() {
    if (this.checkbox.checked) {
      this.switchTarget.classList.add('bg-neutral-900')
      this.switchTarget.classList.remove('bg-neutral-200')
      this.sliderTarget.classList.add('translate-x-3')
      this.sliderTarget.classList.remove('translate-x-0')
    } else {
      this.switchTarget.classList.remove('bg-neutral-900')
      this.switchTarget.classList.add('bg-neutral-200')
      this.sliderTarget.classList.remove('translate-x-3')
      this.sliderTarget.classList.add('translate-x-0')
    }
  }

  toggle() {
    this.checkbox.click()
  }

  get checkbox() {
    return this.element.querySelector('input[type=checkbox]')
  }
}
