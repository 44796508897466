import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

const animationDuration = 200
const bounceAmount = 15
const opacityTransition = 'opacity 150ms ease-in-out 50ms'

export default class extends Controller {
  static values = { stateUrl: String }
  static targets = ['fade', 'hide', 'show', 'stopper', 'indicator']

  connect() {
    this.fadeTargets.forEach((t) => (t.style.transition = opacityTransition))
    this.synchronizeControlledTargets()
  }

  toggle({ target }) {
    if (!target) return
    if (
      this.fadeTargets.some(
        (t) => t.contains(target) && !this.isContent(target)
      )
    )
      return
    if (
      this.showTargets.some(
        (t) => t.contains(target) && !this.isContent(target)
      )
    )
      return
    if (
      this.stopperTargets.some(
        (t) => t.contains(target) && !this.isContent(target)
      )
    )
      return
    const [keyframes, opts] = this.buildAnimation()

    this.element.animate(keyframes, opts)
    this.expanded = !this.expanded
    this.persistState()
  }

  // private

  isContent(element) {
    return !!element.dataset['content']
  }

  persistState() {
    patch(this.stateUrlValue, {
      body: JSON.stringify({
        phase: {
          id: this.phaseId,
          expanded: this.expanded,
        },
      }),
    })
  }

  synchronizeControlledTargets() {
    this.hideTargets.forEach((target) => {
      this.invertDisplay(target)
    })

    this.showTargets.forEach((target) => {
      this.syncDisplay(target)
    })

    this.fadeTargets.forEach((target) => {
      this.syncDisplay(target)
      this.syncOpacity(target)
    })

    if (this.expanded) {
      this.indicatorTarget.classList.add('rotate-180')
    } else {
      this.indicatorTarget.classList.remove('rotate-180')
    }
  }

  syncDisplay(target) {
    if (this.expanded) {
      target.classList.remove('hidden')
    } else {
      target.classList.add('hidden')
    }
  }

  invertDisplay(target) {
    if (this.expanded) {
      target.classList.add('hidden')
    } else {
      target.classList.remove('hidden')
    }
  }

  syncOpacity(target) {
    if (this.expanded) {
      target.classList.remove('opacity-0')
      target.classList.add('opacity-100')
    } else {
      target.classList.remove('opacity-100')
      target.classList.add('opacity-0')
    }
  }

  buildAnimation() {
    return [
      this.heightKeyframes,
      { duration: animationDuration, easing: 'ease-in-out' },
    ]
  }

  get heightKeyframes() {
    const startContainer = this.element.getBoundingClientRect()
    this.fadeTargets.forEach((target) => {
      this.invertDisplay(target)
    })

    const endContainer = this.element.getBoundingClientRect()
    this.fadeTargets.forEach((target) => {
      this.syncDisplay(target)
    })

    let bounceHeight
    const startHeight = startContainer.height
    const endHeight = endContainer.height

    if (startHeight < endHeight) {
      bounceHeight = endContainer.height + bounceAmount
    } else {
      bounceHeight = endContainer.height - bounceAmount
    }

    return [
      { height: `${startHeight}px` },
      { height: `${bounceHeight}px`, offset: 0.7 },
      { height: `${endHeight}px` },
    ]
  }

  set expanded(expanded) {
    this.element.setAttribute('aria-expanded', expanded)

    this.synchronizeControlledTargets()
  }

  get expanded() {
    return this.element.getAttribute('aria-expanded') == 'true'
  }

  get phaseId() {
    return this.element.id.split('_')[1]
  }
}
