import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
    index: Number,
  }

  addItem(event) {
    event.target.disabled = true

    const data = new FormData()
    data.append('index', this.indexValue)

    post(this.urlValue, {
      body: data,
      responseKind: 'turbo-stream',
    })
  }
}
