import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']

  connect() {
    this.buttonTextElement.innerText = this.buttonTarget.dataset.text
  }

  toggle() {
    if (this.buttonTarget.innerText === this.buttonTarget.dataset.text) {
      this.buttonTextElement.innerText = this.buttonTarget.dataset.alternateText
    } else {
      this.buttonTextElement.innerText = this.buttonTarget.dataset.text
    }
  }

  get buttonTextElement() {
    return this.buttonTarget.querySelector('p')
  }
}
