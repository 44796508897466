import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }

  onIframeEvent(event) {
    console.log(event)

    if (pandadocSigned(event) || adobeSigned(event)) {
      this.markAsSigned()
      this.completeTask()
    }
  }

  markAsSigned() {
    patch(this.urlValue)
  }

  completeTask() {
    document.querySelector('[role=mark-complete]')?.click()
  }
}

function pandadocSigned(event) {
  if (event.origin !== 'https://app.pandadoc.com') {
    return false
  }

  // https://developers.pandadoc.com/reference/embed-a-document
  // https://developers.pandadoc.com/docs/javascript-form-embed
  try {
    const data = JSON.parse(event.data)
    return (
      data.event === 'embed.form.completed' ||
      data.event === 'session_view.document.completed'
    )
  } catch (e) {
    return false
  }
}

// https://opensource.adobe.com/acrobat-sign/developer_guide/events.html
function adobeSigned(event) {
  try {
    const data = JSON.parse(event.data)
    return data.type === 'ESIGN'
  } catch (e) {
    return false
  }
}
