import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ['button', 'panel']
  static values = {
    startOpen: { type: Boolean, default: false },
  }

  connect() {
    createPopper(this.buttonTarget, this.panelTarget.parentElement, {
      placement: 'top-end',
    })

    if (this.startOpenValue) {
      this.open()
    }
  }

  open() {
    enter(this.panelTarget)
    this.buttonTarget.classList.add('invisible')
  }

  close() {
    leave(this.panelTarget)
    this.buttonTarget.classList.remove('invisible')
  }

  toggle() {
    this.panelTarget.classList.toggle('hidden')
    this.buttonTarget.classList.toggle('invisible')
  }
}
