import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }

  initialize() {
    patch(this.urlValue)
    this.element.remove()
  }
}
