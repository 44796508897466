import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['teamMember', 'searchField', 'searchEmailField']

  initialize() {
    this.selectedTeamMembers = new Set([])
  }

  teamMemberTargetConnected(target) {
    if (target.checked) {
      this.selectedTeamMembers.add(target.value)
    } else {
      this.selectedTeamMembers.delete(target.value)
    }

    this.#syncSearchEmailField()
  }

  selectTeamMember(event) {
    if (event.target.checked) {
      this.selectedTeamMembers.add(event.target.value)
    } else {
      this.selectedTeamMembers.delete(event.target.value)
    }

    this.#syncSearchEmailField()
  }

  clearSearch(_event) {
    this.searchFieldTarget.value = ''
    this.searchFieldTarget.form.requestSubmit()
  }

  #syncSearchEmailField() {
    this.searchEmailFieldTarget.value = Array.from(
      this.selectedTeamMembers
    ).join(',')
  }
}
