import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  clean(event) {
    let input = event.currentTarget
    setTimeout(function () {
      let clean_url = input.value.replace(/^https?\:\/\//i, '')
      input.value = clean_url
    }, 50)
  }
}
