import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    duration: Number,
  }

  connect() {
    this.fadeOut()
  }

  fadeOut() {
    this.savedTimer = setTimeout(() => {
      this.element.classList.add('opacity-0')
    }, this.durationValue)
  }

  disconnect() {
    if (this.savedTimer) {
      clearTimeout(this.savedTimer)
    }

    this.element.remove()
  }
}
