import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['preview']
  static values = { maxFileSize: Number }

  updatePreview(event) {
    if (!event.target.files) {
      this.previewTarget.src = ''
      return
    }

    if (event.target.files[0].size > this.maxFileSizeValue) {
      event.preventDefault()
      alert(
        `This file is too large: max file size is ${this.bytesToSize(
          this.maxFileSizeValue
        )}`
      )
      return
    }

    const fileReader = new FileReader()
    fileReader.onload = (oFREvent) => {
      this.previewTarget.src = oFREvent.target.result
    }

    fileReader.readAsDataURL(event.target.files[0])
  }

  bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes == 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes}${sizes[i]}`
    return `${(bytes / 1024 ** i).toFixed(1)}${sizes[i]}`
  }
}
