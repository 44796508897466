import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }
  static targets = ['property', 'operator', 'expectedValue']

  onChange(event) {
    get(this.urlValue, {
      query: {
        hubspot_property_id: this.propertyTarget?.value,
        operator: this.operatorTarget?.value,
        expected_value: this.expectedValueTarget?.value,
      },
      responseKind: 'turbo-stream',
    })
  }

  onRowRemoved(event) {
    this.element.querySelectorAll('input,select').forEach((input) => {
      input.removeAttribute('required')
    })

    this.element.classList.add('hidden')
  }
}
