import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['original', 'input']

  revert() {
    this.inputTargets.forEach((input, idx) => {
      input.value = this.originalTargets[idx].innerHTML.trim()
    })
  }
}
