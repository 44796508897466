import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content', 'indicator', 'detailsElement']
  static values = {
    open: Boolean,
  }

  connect() {
    this.openValueChanged()
  }

  toggle() {
    this.openValue = !this.openValue
  }

  show() {
    if (this.hasDetailsElementTarget) this.detailsElementTarget.open = true
    this.openValue = true
  }

  hide() {
    if (this.hasDetailsElementTarget) this.detailsElementTarget.open = false
    this.openValue = false
  }

  openValueChanged() {
    if (this.openValue) {
      if (this.hasIndicatorTarget) {
        this.indicatorTarget.classList.add('rotate-90')
      }

      for (const element of this.contentTargets) {
        element.classList.remove('hidden')
      }
    } else {
      if (this.hasIndicatorTarget) {
        this.indicatorTarget.classList.remove('rotate-90')
      }

      for (const element of this.contentTargets) {
        element.classList.add('hidden')
      }
    }
  }
}
