import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toolbar']

  toggle(event) {
    const toolbar = this.element.querySelector('trix-toolbar')
    const editor = this.element.querySelector('trix-editor')
    toolbar.classList.toggle('hidden')
    editor.focus()

    if (toolbar.classList.contains('hidden')) {
      editor.classList.remove('pt-0')
      event.target.classList.remove('opacity-100')
      event.target.classList.add('opacity-60')
    } else {
      editor.classList.add('pt-0')
      event.target.classList.remove('opacity-60')
      event.target.classList.add('opacity-100')
    }
  }
}
