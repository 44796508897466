import { Controller } from '@hotwired/stimulus'
import { genRanHex } from '../../helpers/generators_helpers'

export default class extends Controller {
  static targets = [
    'passwordField',
    'passwordFieldInput',
    'passwordFieldHelperText',
  ]

  togglePasswordProtection(e) {
    const passwordProtected = e.target.checked
    if (passwordProtected) {
      this.passwordFieldTarget.classList.remove('hidden')
      this.passwordFieldInputTarget.disabled = false
      this.passwordFieldInputTarget.focus()
    } else {
      this.passwordFieldTarget.classList.add('hidden')
      this.passwordFieldInputTarget.disabled = true
    }
  }

  generatePassword(_e) {
    this.passwordFieldInputTarget.value = genRanHex(8)
    this.passwordFieldHelperTextTarget.classList.remove('hidden')
  }
}
