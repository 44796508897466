import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { observeMutations } from '../../helpers/mutation_observer_helpers'

export default class extends Controller {
  static targets = ['badge', 'trigger']
  static values = {
    path: String,
  }

  initialize() {
    observeMutations.bind(this)(this.toggleVisibility)
  }

  connect() {
    this.fetch()
  }

  triggerTargetConnected() {
    this.fetch()
  }

  fetch() {
    get(this.pathValue, { responseKind: 'turbo-stream' })
  }

  toggleVisibility() {
    this.badgeTargets.forEach((t) => {
      if (t.childNodes.length === 0) {
        t.classList.add('hidden')
      } else {
        t.classList.remove('hidden')
      }
    })
  }
}
