import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ['button', 'nav']

  connect() {
    if (this.savedCollapsedState) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  expand() {
    this.clearCollapsedState()
    this.navTarget.classList.remove('hidden')
    this.buttonTarget.classList.add('hidden')
  }

  smoothExpand() {
    this.expand()
    enter(this.navTarget)
  }

  smoothCollapse() {
    leave(this.navTarget).then(() => {
      this.collapse()
    })
  }

  collapse() {
    this.navTarget.classList.add('hidden')
    this.buttonTarget.classList.remove('hidden')
    this.setCollapsedState()
  }

  get savedCollapsedState() {
    return localStorage.getItem(this.collapsedKey) === 'true'
  }

  setCollapsedState() {
    localStorage.setItem(this.collapsedKey, 'true')
  }

  clearCollapsedState() {
    localStorage.removeItem(this.collapsedKey)
  }

  get collapsedKey() {
    return `nav-collapsed-${this.element.dataset['key']}`
  }
}
