import { Controller } from '@hotwired/stimulus'
import Tribute from 'tributejs'
import {
  menuItemTemplate,
  noMatchTemplate,
} from '../../helpers/personalization_tokens'

// There is a version of this for input fields
export default class extends Controller {
  static values = { url: String }

  connect() {
    this.editor = this.element.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.element)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      trigger: '{{',
      allowSpaces: true,
      lookup: 'token',
      values: this.fetchPersonalizationTokens.bind(this),
      menuItemTemplate: menuItemTemplate,
      noMatchTemplate: noMatchTemplate,
    })
    this.tribute.attach(this.element)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.element.addEventListener('tribute-replaced', this.replaced)
  }

  fetchPersonalizationTokens(text, callback) {
    const url = new URL(this.urlValue)
    url.search = new URLSearchParams({ q: text })

    fetch(url, { headers: { Accept: 'application/json' } })
      .then((response) => response.json())
      .then((opts) => callback(opts))
      .catch((_) => callback([]))
  }

  replaced(e) {
    if (e.detail.item) {
      this.editor.insertString(`{{ ${e.detail.item.original.token} }} `)
    }
  }

  _pasteHtml(_, startPos, endPos) {
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([position - endPos + startPos, position + 1])
    this.editor.deleteInDirection('backward')
  }
}
