import Rails from '@rails/ujs'
import Swal from 'sweetalert2'

// Based on https://derk-jan.com/2020/10/rails-ujs-custom-confirm/
let __SkipConfirmation = false

function showDialog(element, onConfirm) {
  const message = element.getAttribute('data-confirm')
  const title = element.getAttribute('data-confirm-title') || 'Confirm action'
  const confirmButtonText =
    element.getAttribute('data-confirm-button-text') || 'Confirm'
  const cancelButtonText =
    element.getAttribute('data-cancel-button-text') || 'Cancel'

  Swal.fire({
    html: `
      <div class="flex flex-col space-y-2">
        <div class="text-left text-lg font-bold text-gray-800">
          ${title}
        </div>
        <div class="text-left font-normal text-base text-gray-800 font-sans leading-7">
          ${message}
        </div>
      </div>
    `,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    buttonsStyling: false,
    reverseButtons: true,
    customClass: {
      confirmButton: 'btn-primary ml-3',
      cancelButton: 'btn-tertiary',
      popup: '!rounded-xl',
      actions: '!justify-end !mx-0 !px-7',
    },
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm()
    }
  })
}

Rails.confirm = function (_message, element) {
  if (__SkipConfirmation) {
    return true
  }

  showDialog(element, () => {
    __SkipConfirmation = true
    element.click()
    __SkipConfirmation = false
  })

  return false
}
