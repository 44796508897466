import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class extends Controller {
  initialize() {
    this.debouncedSave = debounce(this.save.bind(this), 500)
  }

  save() {
    this.element.requestSubmit()
    Turbo.cache.clear()
  }

  saveDebounce() {
    this.debouncedSave()
  }

  cancelSaveDebounce() {
    this.debouncedSave.cancel()
  }

  trapEnter(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
    }
  }
}
