import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'link']

  connect() {
    this.sync()
  }

  sync() {
    const previewUrl = new URL('https://iframely.com/try')

    if (this.hasSourceTarget && this.sourceTarget.value) {
      previewUrl.searchParams.set('url', this.sourceTarget.value)
    }

    this.linkTarget.href = previewUrl.toString()
  }
}
