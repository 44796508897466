import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'
import confetti from 'canvas-confetti'

export default class extends Controller {
  static targets = ['completedActions', 'todoActions', 'bar', 'barMessage']

  markAsDone() {
    leave(this.todoActionsTarget)

    Promise.all([enter(this.barTarget), enter(this.barMessageTarget)]).then(
      () => {
        this.fireConfetti()

        setTimeout(() => {
          leave(this.barTarget)
          leave(this.barMessageTarget)
          enter(this.completedActionsTarget)
        }, 1000)
      }
    )
  }

  markAsTodo() {
    leave(this.completedActionsTarget)
    enter(this.todoActionsTarget)
  }

  fireConfetti() {
    this.confetti = confetti.create(this.canvas, { resize: true })

    this.fire(0.25, { spread: 26, startVelocity: 55 })
    this.fire(0.2, { spread: 60 })
    this.fire(0.35, { spread: 100, decay: 0.91, scalar: 0.8 })
    this.fire(0.1, { speed: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 })
    this.fire(0.1, { speed: 120, startVelocity: 45 })
  }

  fire(particleRatio, options) {
    let count = 200
    const { top, height, left, width } = this.element.getBoundingClientRect()
    const x = (left + width / 2) / window.innerWidth
    const y = (top + height / 2) / window.innerHeight
    let defaults = { origin: { x, y } }

    this.confetti(
      Object.assign({}, defaults, options, {
        particleCount: Math.floor(count * particleRatio),
      })
    )
  }

  syncMarkDoneButton(data) {
    const markDoneButton = this.todoActionsTarget.querySelector(
      "[role='mark-complete']"
    )

    if (markDoneButton) {
      if (data.disable) {
        markDoneButton.setAttribute('disabled', true)
      } else {
        markDoneButton.removeAttribute('disabled')
      }

      if (data.tooltip) {
        const buttonParent = markDoneButton.parentElement
        if (data.tooltip.show) {
          buttonParent.dataset['tippyContent'] = data.tooltip.content
          buttonParent.dataset['controller'] = 'tippy'
        } else {
          buttonParent.dataset['controller'] = buttonParent.dataset[
            'controller'
          ]?.replace('tippy', '')
          buttonParent.removeAttribute('data-tippy-content')
          buttonParent._tippy?.destroy()
        }
      }
    }
  }

  get canvas() {
    return this.element.querySelector('#confetti')
  }
}
