import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }

  onIframeEvent(event) {
    if (
      hubspotMeetingBooked(event) ||
      calendlyMeetingBooked(event) ||
      caldotcomMeetingBooked(event) ||
      chiliPiperMeetingBooked(event)
    ) {
      this.markAsBooked()
      this.completeTask()
    }
  }

  markAsBooked() {
    patch(this.urlValue)
  }

  completeTask() {
    document.querySelector('[role=mark-complete]')?.click()
  }
}

function hubspotMeetingBooked(event) {
  // https://gist.github.com/fbonawiede/556499ea3c0a0a3ae831f7d29e6a309f
  return event.data.meetingBookSucceeded || event.data.meetingCreated
}

function calendlyMeetingBooked(event) {
  // https://help.calendly.com/hc/en-us/articles/223147027?tab=advanced#6
  return event.data.event === 'calendly.event_scheduled'
}

function caldotcomMeetingBooked(event) {
  // https://cal.com/docs/core-features/embed/set-up-your-embed
  return event.data.type === 'bookingSuccessful'
}

function chiliPiperMeetingBooked(event) {
  // https://help.chilipiper.com/hc/en-us/articles/1500011381522
  return event.data.action === 'booking-confirmed'
}
