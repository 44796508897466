import { Controller } from '@hotwired/stimulus'
import { observeMutations } from '../helpers/mutation_observer_helpers'

export default class extends Controller {
  static targets = ['container']
  static values = {
    selector: String,
    paginated: Boolean,
    paginatedTotalCount: Number,
    paginatedPerPageCount: Number,
    dataAttributeName: String,
  }

  connect() {
    observeMutations.bind(this)(this.countElements, this.containerTarget)
  }

  countElements() {
    const elements = this.containerTarget.querySelectorAll(this.selectorValue)
    if (this.paginatedValue) {
      let newPaginatedTotalCount = elements.length
      if (this.paginatedTotalCountValue > this.paginatedPerPageCountValue) {
        newPaginatedTotalCount =
          this.paginatedTotalCountValue -
          this.paginatedPerPageCountValue +
          elements.length
      }

      this.element.dataset[this.dataAttributeNameValue] = newPaginatedTotalCount
    } else {
      this.element.dataset[this.dataAttributeNameValue] = elements.length
    }
  }
}
