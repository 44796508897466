import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source', 'input']
  static values = { truthy: Array }

  connect() {
    this.sync()
  }

  sync() {
    const value = this.sourceTarget.value

    if (this.truthyValue.includes(value)) {
      this.enable()
    } else {
      this.disable()
    }
  }

  enable() {
    this.inputTargets.forEach((c) => (c.disabled = false))
  }

  disable() {
    this.inputTargets.forEach((c) => (c.disabled = true))
  }
}
