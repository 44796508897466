import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['reveal']
  static classes = ['clamp']

  connect() {
    this.addClamp()

    if (this.isClamped()) {
      this.showReveal()
    } else {
      this.hideReveal()
    }
  }

  reveal() {
    this.removeClamp()
    this.hideReveal()
  }

  isClamped() {
    return this.clampableTarget.scrollHeight > this.clampableTarget.clientHeight
  }

  addClamp() {
    this.clampableTarget.classList.add(this.clampClass)
  }

  removeClamp() {
    this.clampableTarget.classList.remove(this.clampClass)
  }

  showReveal() {
    this.revealTarget.classList.remove('hidden')
  }

  hideReveal() {
    this.revealTarget.classList.add('hidden')
  }

  get clampableTarget() {
    // Due to a Safari issue, we need to apply clamping to the `.trix-content` element
    // It is not sufficient to clamp parent DOM nodes (which works fine in Chrome)
    // Ref: https://github.com/tailwindlabs/tailwindcss-line-clamp/issues/7#issuecomment-850881260
    return this.element.querySelector('.trix-content')
  }
}
