import { Controller } from '@hotwired/stimulus'
import hotkeys from 'hotkeys-js'

export default class extends Controller {
  connect() {
    this.summaryElement?.setAttribute('aria-haspopup', 'menu')
    this.addKeyboardShortcuts()
  }

  disconnect() {
    this.removeKeyboardShortcuts()
    this.close()
  }

  closeOnClickOutside({ target }) {
    if (this.element.contains(target)) return

    this.close()
  }

  closeOnFocusOutside({ target }) {
    if (!this.element.open) return
    if (this.element.contains(target)) return

    this.close()
  }

  close() {
    this.element.open = false
  }

  // private

  addKeyboardShortcuts() {
    hotkeys('esc', () => this.close())
  }

  removeKeyboardShortcuts() {
    hotkeys.unbind('esc')
  }

  get summaryElement() {
    return this.element.querySelector('summary')
  }
}
