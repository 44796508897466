import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String }
  static targets = ['fieldType', 'hubspotPropertyId']

  onSelect(_event) {
    get(this.urlValue, {
      query: {
        field_type: this.fieldTypeTarget?.value,
        hubspot_property_id: this.hasHubspotPropertyIdTarget
          ? this.hubspotPropertyIdTarget?.value
          : null,
      },
      responseKind: 'turbo-stream',
    })
  }
}
