import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.updateTimezone()
  }

  updateTimezone() {
    const timeZone = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })
      .formatToParts(new Date())
      .find((part) => part.type === 'timeZoneName').value
    this.element.innerHTML = timeZone
    this.element.title = 'Your local time zone is ' + timeZone
  }
}
