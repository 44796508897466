import { Controller } from '@hotwired/stimulus'
import PDFObject from 'pdfobject'

export default class extends Controller {
  static values = { url: String }
  static targets = ['lightbox', 'container', 'linkMobile']

  connect() {
    PDFObject.embed(this.urlValue, this.containerTarget)
  }

  open() {
    if (PDFObject.supportsPDFs && window.self === window.top) {
      this.lightboxTarget.showModal()
      this.containerTarget.firstChild.focus()
    } else {
      this.linkMobileTarget.click()
    }
  }
}
