import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static targets = ['item']
  static values = {
    url: String,
  }

  connect() {
    Sortable.create(this.element, {
      handle: '.sortable-handle',
      animation: 150,
      forceFallback: true,
      filter: '.sortable-ignore',
      onUpdate: () => this.reorderItems(),
      onAdd: () => this.reorderItems(),
    })
  }

  reorderItems() {
    const data = new FormData()
    data.append('ids', this.orderedIds)

    patch(this.urlValue, {
      body: data,
    })
  }

  get orderedIds() {
    return this.itemTargets.map((t) => t.dataset.reorderId)
  }
}
