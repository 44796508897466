import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['bookingLink', 'bookingLinkInput']

  toggleHideBlock(_event) {
    this.bookingLinkTarget.classList.add('hidden')
    this.bookingLinkInputTarget.disabled = true
  }

  toggleBookingLink(_event) {
    this.bookingLinkInputTarget.disabled = false
    this.bookingLinkTarget.classList.remove('hidden')
    this.bookingLinkInputTarget.focus()
  }

  fillCalendarUrlWithOwnerCalendarUrl(event) {
    this.bookingLinkInputTarget.value = event.target.innerText
    this.bookingLinkInputTarget.focus()
  }
}
