import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String,
  }

  connect() {
    this.#geolocateParticipant()
  }

  async #geolocateParticipant() {
    try {
      const response = await fetch('https://ipapi.co/json/')
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      const location = [data.city, data.region_code, data.country]
        .filter((e) => e)
        .join(', ')

      const formData = new FormData()
      formData.append('location', location)

      await post(this.urlValue, { body: formData })
    } catch (error) {
      console.log('Failed to geolocate:', error)
    }
  }
}
