import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = [
    'enabledBg',
    'enabledIndicator',
    'disabledBg',
    'disabledIndicator',
  ]
  static targets = ['button', 'indicator', 'formField', 'label']
  static values = { isEnabled: Boolean, onLabel: String, offLabel: String }

  connect() {
    this.applyElementState()
  }

  toggle(_event) {
    this.isEnabledValue = !this.isEnabled
  }

  // Private methods:

  get isEnabled() {
    return this.isEnabledValue
  }

  applyElementState() {
    this.refreshFormField()
    this.isEnabled ? this.activate() : this.deactivate()
  }

  refreshFormField() {
    const label = this.isEnabled ? this.onLabelValue : this.offLabelValue
    this.labelTarget.innerHTML = label
    this.formFieldTarget.value = this.isEnabled
  }

  activate() {
    this.buttonTarget.classList.add(this.enabledBgClass)
    this.buttonTarget.classList.remove(this.disabledBgClass)

    this.indicatorTarget.classList.add(this.enabledIndicatorClass)
    this.indicatorTarget.classList.remove(this.disabledIndicatorClass)
  }

  deactivate() {
    this.buttonTarget.classList.add(this.disabledBgClass)
    this.buttonTarget.classList.remove(this.enabledBgClass)

    this.indicatorTarget.classList.add(this.disabledIndicatorClass)
    this.indicatorTarget.classList.remove(this.enabledIndicatorClass)
  }

  isEnabledValueChanged() {
    this.applyElementState()
  }
}
