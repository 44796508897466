import { Controller } from '@hotwired/stimulus'
import { copyItems } from '../../helpers/clipboard_helpers'

export default class extends Controller {
  static targets = ['copyLinkButton']

  copyLinkForEmail(e) {
    e.preventDefault()
    e.stopPropagation()

    const clipboard_item = new ClipboardItem({
      'text/html': this.#buildClipboardHtmlContent(e.params),
      'text/plain': this.#buildPlainTextContent(e.params),
    })

    copyItems([clipboard_item], e.params.url)

    const originalHtml = this.copyLinkButtonTarget.innerHTML
    this.copyLinkButtonTarget.innerHTML = e.params.message
    this.copyLinkButtonTarget.disabled = true

    if (this.textTimer) {
      clearTimeout(this.textTimer)
    }

    this.textTimer = setTimeout(() => {
      this.copyLinkButtonTarget.innerHTML = originalHtml
      this.copyLinkButtonTarget.disabled = false
    }, 2000)
  }

  #buildClipboardHtmlContent(params) {
    const showImage = params.imageUrl && params.imageUrl.length > 0
    const roomName = params.roomName || 'Shared room'

    let content

    if (showImage) {
      content = `
        <a href="${params.url}">${roomName}</a>
        <br>
        <a href="${params.url}">
          <img src="${params.imageUrl}" width="216px" height="114px">
        </a>`
    } else {
      content = `<a href="${params.url}">${roomName}</a>`
    }

    return new Blob([content], { type: 'text/html' })
  }

  #buildPlainTextContent(params) {
    return new Blob([params.url], { type: 'text/plain' })
  }
}
