import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['parent', 'child']

  connect() {
    this.sync()
  }

  sync() {
    if (this.anyParentEnabled) {
      this.childTargets.forEach((c) => {
        if (c.dataset.disableWhenParentEnabled == 'true') {
          c.disabled = true
        } else {
          c.disabled = false
        }
      })
    } else {
      this.childTargets.forEach((c) => {
        if (c.dataset.disableWhenParentEnabled == 'true') {
          c.disabled = false
        } else {
          c.disabled = true
        }
      })
    }
  }

  get anyParentEnabled() {
    return this.parentTargets.some(
      (parentTarget) => parentTarget.selected || parentTarget.checked
    )
  }
}
